import React from 'react'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../../i18n'

import './Menu.css'

function Menu (props) {
    return (
        <div className={'menu-container ' + (props.open ? 'menu-height-100 ' : 'menu-height-0 ') + i18next.t('direction')}>
            {
                props.open
                    ? <div className='menu-list'>
                        {props.children}
                    </div> : null
            }
        </div>
    )
}

export default withTranslation()(Menu)