import React from 'react'
import { Route } from 'react-router-dom'

import Home from './Game/Home'
import GameLevel from './Game/GameLevel'
import GameScene from './Game/GameScene'
import About from './Common/About'
import StatsSummary from './Stats/StatsSummary'
import StatsCategory from './Stats/StatsCategory'

import './Dashboard.css'

function Dashboard () {
    return (
        <div className='dashboard'>
            <Route exact path='/app' component={Home} />
            <Route exact path='/app/level' component={GameLevel} />
            <Route exact path='/app/game' component={GameScene} />
            <Route exact path='/app/about' component={About} />
            <Route exact path='/app/stats/summary' component={StatsSummary} />
            <Route exact path='/app/stats/category' component={StatsCategory} />
        </div>
    )
}

export default Dashboard
