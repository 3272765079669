import React from 'react'
import { useTranslation } from 'react-i18next'

import './LanguageSelector.css'

const LanguageSelector = (props) => {
  const { i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  return (
    <div onChange={changeLanguage} className='language-body' >
      <input type="radio" value="he" name="language" defaultChecked={ i18n.language === 'he'? true : false} /> עברית
      <input type="radio" value="en" name="language" defaultChecked={ i18n.language === 'en'? true : false} /> English
    </div>
  )
}

export default LanguageSelector