export const LEVEL_NUM = 4

export const LEVELS = {
    FirstLetter: 'first-letter',
    LastLetter: 'last-letter',
    MiddleLetter: 'middle-letter',
    EntireWord: 'entire-word',
    All: 'all'
}

export const WORD_STATUS = {
    Solved: 'solved',
    Mistake: 'mistake',
    Pending: 'pending',
    All: 'all'
}

export const MENU_ITEMS = {
    NewGame: '0',
    About: '1',
    Stats: '2'
}

export const SUB_TYPE = {
    Positive: 'positive',
    Negative: 'negative'
}