export const confettiDesign = [
    {
        level: 'first-letter',
        confetti: ['#D98FBF', '#BD4BBF', '#F2CEA2', '#B3ECF6', '#597475', '#D98FBF', '#BD4BBF', '#F2CEA2', '#B3ECF6', '#597475', '#D98FBF', '#BD4BBF', '#F2CEA2', '#B3ECF6', '#597475', '#D98FBF', '#BD4BBF']
    },
    {
        level: 'last-letter',
        confetti: ['#ffffff', '#F25E6B', '#D9CDDE', '#FEB6C8', '#B9FAF9', '#F8E053', '#ffffff', '#F25E6B', '#D9CDDE', '#FEB6C8', '#B9FAF9', '#F8E053',  '#ffffff', '#F25E6B', '#D9CDDE', '#FEB6C8', '#B9FAF9']
    },
    {
        level: 'middle-letter',
        confetti: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#ffffff', '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#ffffff', '#FF9800', '#FF5722', '#795548']
    },
    {
        level: 'entire-word',
        confetti: ['#FD4038', '#E33270', '#FA43DF', '#C132E3', '#A138FD', '#F22233', '#3C56A6', '#38A640', '#FFFFFF', '#F29F05', '#B22574', '#00A7CF', '#B3DF40', '#ffffff', '#FDEC23', '#EE2B87', '#E70F1E']
    }
]

export const backgroundDesign = [
    { background: 'linear-gradient(45deg, #feffff 0%, #ededed 100%)' },
    { background: 'linear-gradient(45deg, rgb(45, 54, 76) 0%, rgb(37, 45, 63) 100%)' },
    { background: 'linear-gradient(rgb(169, 3, 41) 0%, rgb(143, 2, 34) 44%, rgb(109, 0, 25) 100%)' },
    { background: 'linear-gradient(45deg, rgb(69, 72, 77) 0%, rgb(0, 0, 0) 100%)' }
]