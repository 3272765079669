import React, { useEffect } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import ReactPlayer from 'react-player'
import Particles from 'react-particles-js'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { tagPageView } from '../Common/TagManager'

import './About.css'

function About () {
    const { width, height } = useWindowSize()

    useEffect(() => {
        tagPageView('/app/about', 'about')
    },[])
    

    return (
        <div className='about'>
            <div className={'about-body ' + i18next.t('direction') }>
                <div className='particles-container'>
                    <Particles
                        width={width}
                        height={height}
                        style={{ width: width, height:height }}
                        params={{
                            "particles": {
                                "number": {
                                    "value": 20,
                                    "density": {
                                        "enable": true,
                                        "value_area": 800
                                    }
                                },
                                "line_linked": {
                                    "enable": false
                                },
                                "move": {
                                    "speed": 1,
                                    "out_mode": "out"
                                },
                                "shape": {
                                    "type": [
                                        "image"
                                    ],
                                    "image": [
                                        {
                                            "src": process.env.PUBLIC_URL + '/first-letter.svg',
                                            "height": 20,
                                            "width": 20
                                        },
                                        {
                                            "src": process.env.PUBLIC_URL + '/last-letter.svg',
                                            "height": 20,
                                            "width": 20
                                        },
                                        {
                                            "src": process.env.PUBLIC_URL + '/middle-letter.svg',
                                            "height": 20,
                                            "width": 20
                                        },
                                        {
                                            "src": process.env.PUBLIC_URL + '/entire-word.svg',
                                            "height": 20,
                                            "width": 20
                                        }

                                    ]
                                },
                                "color": {
                                    "value": "#fff"
                                },
                                "size": {
                                    "value": 30,
                                    "random": false,
                                    "anim": {
                                        "enable": true,
                                        "speed": 4,
                                        "size_min": 10,
                                        "sync": true
                                    }
                                }
                            },
                            "retina_detect": true
                        }} />
                </div>
                <Card className='about-card-root'>
                    <CardContent>
                        <Typography className='title' color='textSecondary' gutterBottom>
                        { i18next.t('aboutthegame.label') }
                        </Typography>
                        <Typography variant='h5' component='h2'>
                        { i18next.t('about_h2.label') }
                        </Typography>
                        <Typography className='pos' color='textSecondary'>
                        { i18next.t('about_h3.label') }
                        <br/><br/>
                        </Typography>
                        <Typography variant='body1' component='p'>
                        { i18next.t('about_description.label') }
                            <br/><br/>
                        </Typography>
                        <div className='player-wrapper'>
                        <ReactPlayer 
                            url='https://youtu.be/eWy6IY5n7sU' 
                            playing='true'
                            allowfullscreen='false'
                            loop='true' 
                            width='80%'/>
                        </div>
                        <Typography variant='caption' component='p'>
                            <br/>
                            { i18next.t('about_credits.label') } <a href='https://www.flaticon.com/authors/nikita-golubev' title='Nikita Golubev' rel='noreferrer' target='_blank'>Nikita Golubev</a> { i18next.t('from.label') } <a href='https://www.flaticon.com/' title='Flaticon' rel='noreferrer' target='_blank'> www.flaticon.com</a>
                            <br />
                        </Typography>
                        <Typography variant='caption' component='p'>
                            <br/>
                            { i18next.t('about_sounds_credits.label') }
                            <br />
                            <br />
                            { i18next.t('about_improvments.label') } <a href="mailto:info@nirmizrahi.com?subject=Improvement suggestion to alefbetgimel">info@nirmizrahi.com</a>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default withTranslation()(About)
