import React from 'react'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import './Footer.css'

function Footer () {
    return (
        <div className='footer'>
            <div className='footer-line'></div>
            <div className={'footer-text ' + i18next.t('direction') }>
                { i18next.t('footer.label') }
            </div>
        </div>
    )
}

export default withTranslation()(Footer)
