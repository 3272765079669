/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Particles from 'react-particles-js'
import queryString from 'query-string'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { withStyles } from "@material-ui/core/styles"

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { LEVELS } from '../Common/Global'
import { tagPageView, tagEvent } from '../Common/TagManager'
import { confettiDesign, backgroundDesign } from './GameLevelDesign'

import { gameData } from "./GameData"
import GameLetterTile from './GameLetterTile'

import './GameScene.css'

const gameSceneStyles = {
    actionArea: {
      "&:hover $focusHighlight": {
        opacity: '0 !important',
        transition: 'none',
        backgroundColor: '#fff'
      }
    },
    focusHighlight: {
        opacity: '0 !important',
        transition: 'none',
        backgroundColor: '#fff'
    }
  };

function GameScene (props) {
    const [words, setWords] = useState([])
    const [currentWord, setCurrentWord] = useState('')
    const [currentImage, setCurrentImage] = useState('')
    const [currentWordTextFields, setCurrentWordTextFields] = useState([])
    const [wellDoneWordTextFields, setWellDoneWordTextFields] = useState([])
    const [showResults, setShowResults] = React.useState(false)
    const [showTrophy, setShowTrophy] = React.useState(false)
    const [soundUrl, setSoundUrl] = React.useState('')
    let [audio] = useState(new Audio(''))
    const [applauseAudio] = useState(new Audio(process.env.PUBLIC_URL + "/sounds/applause.mp3"))
    const [levelConfetti, setLevelConfetti] = React.useState('')

    const history = useHistory()
    const { width, height } = useWindowSize()
    const textFieldsrefs = useRef([...new Array(3)].map(() => React.createRef()))

    let { category,level } = queryString.parse(props.location.search)
    const { classes } = props

    const trophy = process.env.PUBLIC_URL + "/trophy.svg"

    useEffect(() => {
        if (currentWord !== '') {
            tagPageView(window.location.hash.replace('#', '').replace('?category=', '/').replace('&level=', '/') + '/' + currentWord, 'game scene')
        }
    },[currentWord])

    function handleSceneSolved (idx, solved) {
        const lettersSolvedArray = JSON.parse(localStorage.getItem('currentWordTextFieldsSolved'))
        lettersSolvedArray[idx] = solved
        
        localStorage.setItem('currentWordTextFieldsSolved', JSON.stringify(lettersSolvedArray))

        let checker = arr => arr.every(v => v === true);

        if (checker(lettersSolvedArray)) {
            //Scene Solved!
            markWordAsSolved(true)

            // console.log('Scene Solved!')
            localStorage.removeItem('currentWordTextFieldsSolved')
        }
        else {
            //setShowResults(false)
            
            if (solved === true) {
                //Focus on next textfield
                let letterIdx = idx + 1
                while (textFieldsrefs.current.length > idx &&
                        textFieldsrefs.current[letterIdx] &&
                        textFieldsrefs.current[letterIdx].current.disabled) {
                    letterIdx++
                }
                if (textFieldsrefs.current[letterIdx]) {
                        textFieldsrefs.current[letterIdx].current.focus()
                }
                markWordAsSolved(false)
            }
        
        }
    }

    const gaTrackSolved = (value) => {
        let word = ''
        if (textFieldsrefs.current){
            for (let index = 0; index < textFieldsrefs.current.length; index++) {
                word += textFieldsrefs.current[index].current.value
            }
        }


        if (value === 'word' && word !== ''){
            tagEvent('game', 'solved', '/' + category + '/' + word)
        } else if (value === 'category') {
            tagEvent('game', 'solved', '/' + category)
        }
    }

    const normalizeWordsSolvedArray = () => {
        const wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
        if (wordsSolvedArray !== null) {
            //remove any -1 entires (normalize array)
            for (var i=0; i < wordsSolvedArray.length; i++) {
                if (wordsSolvedArray[i] === -1) {
                    wordsSolvedArray[i] = 0 //mark word as pending
                }
            }
            localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
        }
    }

    const clearWordsSolvedArray = () => {
        //clear solved words array in localStorage
        const wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
        if (wordsSolvedArray !== null) {
            //remove any -1 entires (normalize array)
            for (var i=0; i < wordsSolvedArray.length; i++) {
                wordsSolvedArray[i] = 0 //mark word as pending
            }
            localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
        }  
    }

    //Mark word as solved in wordsSolvedArray
    const markWordAsSolved = (wordSolved) => {
        const wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
        if (wordsSolvedArray !== null) {

            if (wordSolved) { //if word solved, show confetti
                setShowResults(true)
                
                //mark word as solved so that it won't appear again
                for (var i=0; i < wordsSolvedArray.length; i++) {
                    if (wordsSolvedArray[i] === -1) {
                        wordsSolvedArray[i] = 1 //mark word as solved so that it won't appear again
                        localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
                        gaTrackSolved('word')
                        break
                    }
                }

                const timer = setTimeout(() => {
                    //hide confetti
                    setShowResults(false)

                    let checker = arr => arr.every(v => v === 1);
                    if (checker(wordsSolvedArray)) {
                        //all words in category were solved
                        gaTrackSolved('category')
                        createWellDoneTextFields()
                        //show confetti
                        setShowResults(true)
                        //show trophy
                        setShowTrophy(true)
                        setCurrentImage(trophy)
                        
                        const timer1 = setTimeout(() => {
                            setShowTrophy(false)
                            setShowResults(false)
                            i18next.languages.forEach(lang => {
                                localStorage.removeItem(lang + '_wordsSolvedArray')
                            });
                            
                            
                            history.push({pathname: '/app'})
                        }, 6000);
                        return () => clearTimeout(timer1);
                    }
                    else {
                        //load new word
                        window.location.reload()
                    }
                }, 5000);
                return () => clearTimeout(timer);
            }
        }
    }

    const getRandomWord = () => {
        if (currentWord !== '' || words.length <= 0) { 
            return 
        }

        localStorage.removeItem('currentWordTextFieldsSolved')

        let randomNumber = Math.floor(Math.random() * words.length)

        // Mark current random word
        const wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
        if (wordsSolvedArray && 
            wordsSolvedArray.length > 0) {
            // get random word that was not solved
            while (wordsSolvedArray[randomNumber] === 1) {
                randomNumber = Math.floor(Math.random() * words.length)
            }
            wordsSolvedArray[randomNumber] = -1 //indicate location in array for current word
            localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
        }

        const image = process.env.PUBLIC_URL + "/images/" + category + "/" + words[randomNumber].image
        
        //const word = words[randomNumber].word
        const wordDict = words[randomNumber]
        let word = ''
        let soundUrl = ''
        if (i18next.language in wordDict.lang) { //lang dictionary has the lang param (e.g. 'he')
          word = wordDict.lang[i18next.language].word
          soundUrl = wordDict.lang[i18next.language].sound + (i18next.language === 'he' ? '.m4a' : '.mp3')
        }
        else { //use hebrew as deafult language
            word = wordDict.lang['he'].word
            soundUrl = wordDict.lang['he'].sound + '.m4a'
        }
        let charIdx = 0

        tagEvent('game', 'word', '/' + category + '/' + word)

        setCurrentImage(image)
        
        setCurrentWord(word)

        setSoundUrl(process.env.PUBLIC_URL + '/sounds/' + i18next.language + '/' + category + '/' + soundUrl)

        const divHome = document.getElementsByClassName('home')

        switch(level) {
            case LEVELS.FirstLetter:
                charIdx = 0
                setLevelConfetti(confettiDesign[0].confetti)
                divHome[0].style.background = backgroundDesign[0].background
                break
            case LEVELS.LastLetter:
                charIdx = word.length-1
                setLevelConfetti(confettiDesign[1].confetti)
                divHome[0].style.background = backgroundDesign[1].background
                break
            case LEVELS.MiddleLetter:
                {
                    charIdx = Math.floor(word.length / 2)
                    let middleChar = word.charAt(charIdx)
                    while (middleChar === ' ' && charIdx >= 0) {
                        charIdx = charIdx - 1
                        middleChar = word.charAt(charIdx)
                    }
                    setLevelConfetti(confettiDesign[2].confetti)
                    divHome[0].style.background = backgroundDesign[2].background
                    break
                }
            case LEVELS.EntireWord:
                charIdx = -1
                setLevelConfetti(confettiDesign[3].confetti)
                divHome[0].style.background = backgroundDesign[3].background
                break
            default:
                break
        }

        const wordArray = word.split('')
        let lettersSolvedArray = new Array(wordArray.length).fill(false)

        textFieldsrefs.current = textFieldsrefs.current.splice(0, wordArray.length);
        for(let i = 0; i < wordArray.length; i++) {
            textFieldsrefs.current[i] = textFieldsrefs.current[i] || React.createRef();
        }

        let lettersArray = new Array(wordArray.length)
        // init current word solved state to false
        for (var i=0; i < wordArray.length; i++) {
            const char = wordArray[i]
            const disabled = charIdx === -1 ? false : i !== charIdx
            const autoFocus = charIdx === -1 ? (i === 0 ? true : false) : i === charIdx
            const uppercase = i === 0 ? true : false
            if (char !== ' ') {
                lettersArray[i] =  <GameLetterTile 
                                        idx={i}
                                        key={i}
                                        disabled={disabled} 
                                        autoFocus={autoFocus}
                                        uppercase={uppercase}
                                        value={char}
                                        handleSceneSolved={handleSceneSolved.bind(this)}
                                        referer={textFieldsrefs.current[i]}></GameLetterTile>
                lettersSolvedArray[i] = disabled
                
            }
            else { //in case of space in word or between words, create empty text box
                lettersArray[i] =  <GameLetterTile 
                                        idx={i}
                                        key={i}
                                        disabled={true}
                                        value={''}
                                        handleSceneSolved={handleSceneSolved.bind(this)}
                                        referer={textFieldsrefs.current[i]}></GameLetterTile>
                lettersSolvedArray[i] = true
            }
        }
       
        localStorage.setItem('currentWordTextFieldsSolved', JSON.stringify(lettersSolvedArray))

        setCurrentWordTextFields(lettersArray)
    }
    const initWordsArray = () => {
        if (words.length <= 0) {
            let filteredCat = gameData.filter(cat => {
                return category.indexOf(cat.category.value) !== -1
            })
            
            const categoryWords = [...filteredCat[0].words]
            setWords(categoryWords)

            //init solved words array in localStorage
            let wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
            if (wordsSolvedArray === null) {
                wordsSolvedArray = new Array(categoryWords.length).fill(0)
                localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
            }
        }
    }

    useEffect(() => {
        initWordsArray()
    })
    
    useEffect(() => {
        getRandomWord()
    })

    useEffect(() => {
        return () => {
            //normalize words solved array on browser back button
            if (history.action === "POP") {
                clearWordsSolvedArray()
            }
        }
    }, [history])

    window.onbeforeunload = (e) => {
        //normalize words solved array on browser refresh button
        normalizeWordsSolvedArray()
    }

    const createWellDoneTextFields = () => {
        const wellDone = i18next.t('welldone.label')
        const wordArray = wellDone.split('')
        let lettersArray = new Array(wordArray.length)

        for (var i=0; i < wordArray.length; i++) {
            const char = wordArray[i]
            if (char !== ' ') {
                lettersArray[i] =  <GameLetterTile 
                                        idx={i}
                                        key={'wellDone_' + i}
                                        disabled={true} 
                                        value={char} />
                
            }
            else { //in case of space in word or between words, create empty text box
                lettersArray[i] =  <GameLetterTile 
                                        idx={i}
                                        key={'wellDone_' + i}
                                        disabled={true} 
                                        value={''}
                                        />
            }
        }
        setWellDoneWordTextFields(lettersArray)
    }

    if (soundUrl !== '') {
        audio = new Audio(soundUrl)
        //audio.play()
    }

    const togglePlay = () => {
        if (audio === null) { return }
        
        audio.play()
    }

    const playApplauseAudio = () => {
        applauseAudio.play()
    }
    
    return (
        <div className='home'>
            <div className={'game-body ' + i18next.t('direction') }>
            { !showResults || !showTrophy ?
                <div className='particles-container'>
                    <Particles
                        width={width}
                        height={height}
                        style={{ width: width, height:height }}
                        params={{
                            "particles": {
                                "number": {
                                    "value": 20,
                                    "density": {
                                        "enable": true,
                                        "value_area": 800
                                    }
                                },
                                "line_linked": {
                                    "enable": false
                                },
                                "move": {
                                    "speed": 1,
                                    "out_mode": "out"
                                },
                                "shape": {
                                    "type": [
                                        "image"
                                    ],
                                    "image": [
                                        {
                                            "src": currentImage,
                                            "height": 20,
                                            "width": 23
                                        }

                                    ]
                                },
                                "color": {
                                    "value": "#fff"
                                },
                                "size": {
                                    "value": 30,
                                    "random": false,
                                    "anim": {
                                        "enable": true,
                                        "speed": 4,
                                        "size_min": 10,
                                        "sync": true
                                    }
                                }
                            },
                            "retina_detect": true
                        }} />
                </div> : <div></div> }
                { showResults ? <Confetti className='confetti' width={width} height={height} colors={levelConfetti}/> : null }
                { showTrophy ? 
                    <div>
                        { playApplauseAudio() }
                        <div className='particles-container'>
                            <Particles
                                width={width}
                                height={height}
                                style={{ width: width, height:height }}
                                params={{
                                    "particles": {
                                        "number": {
                                            "value": 20,
                                            "density": {
                                                "enable": true,
                                                "value_area": 800
                                            }
                                        },
                                        "line_linked": {
                                            "enable": false
                                        },
                                        "move": {
                                            "speed": 1,
                                            "out_mode": "in"
                                        },
                                        "shape": {
                                            "type": [
                                                "image",
	                                            "circle"
                                            ],
                                            "image": [
                                                {
                                                    "src": trophy,
                                                    "height": 30,
                                                    "width": 30
                                                }

                                            ]
                                        },
                                        "color": {
                                            "value": "#fff"
                                        },
                                        "size": {
                                            "value": 30,
                                            "random": true,
                                            "anim": {
                                                "enable": true,
                                                "speed": 4,
                                                "size_min": 10,
                                                "sync": false
                                            }
                                        }
                                    },
                                    "retina_detect": true
                            }} />
                    </div>
                    <Card className='game-card trophy' style={{ 
                                    height:height*0.8,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'}}>
                        <CardActionArea classes={{
                            root: classes.actionArea,
                            focusHighlight: classes.focusHighlight
                            }}>
                            <CardMedia
                                className='game-card-media'
                                component='img'
                                image={trophy} />
                                <CardContent className='card-content-solved'>
                                    {wellDoneWordTextFields}
                                </CardContent>
                        </CardActionArea>
                    </Card> 
                </div>
                        : 
                    <Card className='game-card'>
                        <CardActionArea classes={{
                            root: classes.actionArea,
                            focusHighlight: classes.focusHighlight
                            }}>
                            <CardMedia
                                component='img'
                                height='30%'
                                image={currentImage}
                            />
                            <CardContent className='card-content'>
                                {currentWordTextFields}
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <div className='actions'>
                                <Button size='small' color='primary' onClick={() => { 
                                    tagEvent('game', 'play sound', '/' + category + '/' + currentWord)
                                        togglePlay()
                                    }}> 
                                    <img width='30' height='30' src={process.env.PUBLIC_URL + '/volume.svg'} alt='נגן' ></img>
                                </Button>
                                <Button size='small' color='primary' onClick={() => { 
                                        tagEvent('game', 'change word', '/' + category + '/' + currentWord)
                                        //clear current word tag (-1)
                                        const wordsSolvedArray = JSON.parse(localStorage.getItem(i18next.language + '_wordsSolvedArray'))
                                        if (wordsSolvedArray !== null) {
                                            for (var i=0; i < wordsSolvedArray.length; i++) {
                                                if (wordsSolvedArray[i] === -1) {
                                                    wordsSolvedArray[i] = 0 //mark word as solved so that it won't appear again
                                                    localStorage.setItem(i18next.language + '_wordsSolvedArray', JSON.stringify(wordsSolvedArray))
                                                    break
                                                }
                                            }
                                        }
                                        window.location.reload() 
                                    }}>
                                    <img width='30' height='30' src={process.env.PUBLIC_URL + '/reload.svg'} alt='טען' ></img>
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                    }
            </div>
        </div>
    )
}

export default withTranslation()(withStyles(gameSceneStyles)(GameScene))
