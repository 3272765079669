/* eslint-disable max-len */
import React, { useState } from 'react'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import './GameTile.css'

function GameTile (props) {

    let [audio] = useState(new Audio(''))

    if (props.gameTile.soundUrl !== '') {
        //console.log(props.gameTile.soundUrl)
        audio = new Audio(props.gameTile.soundUrl)
    }

    const togglePlay = (event) => {
        if (audio === null) { return }
        
        audio.play()
    }
    
    return (
        <Card className='game-tile-root' variant='outlined'>
            <CardActionArea>
                <CardMedia style={{backgroundSize:'contain'}} className='game-tile-media' image={props.gameTile.imageurl} onClick={() => props.onGameTileClick(props.gameTile.value)}/>
                <CardContent className='game-tile-card-content' style={{padding:5}}>
                    <Button size='small' color='primary' onClick={() => { 
                            togglePlay()
                        }}>
                        <img width='30' height='30' src={process.env.PUBLIC_URL + '/volume.svg'} alt='נגן' ></img>
                    </Button>
                    <Typography variant='subtitle2'>
                        {props.gameTile.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
            
        </Card>
    )
}

export default GameTile
