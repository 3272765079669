import React, { useState } from 'react'

import './MenuItem.css'

function MenuItem (props) {
    const [hover, setHover] = useState(false);

    function handleHover(e) {
        e.preventDefault();
        // console.log('The menuitem link was clicked.');
        setHover(!hover)
    }
    
    return (
        <div className='menu-item-container'>
            <div
                id={props.id}
                className={'menu-item ' + (hover ? 'menu-item-hover' : 'menu-item-no-hover')}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={props.onClick}
            >
                {props.children}
            </div>
            <div className='menu-item-line' />
        </div>
    )
}

export default MenuItem