import React, { useEffect } from 'react'

import './MenuButton.css'

function MenuButton (props) {

    useEffect(() => {
        //console.log('open changed', props.open);
    }, [props.open])

    function handleClick(e) {
        e.preventDefault();
        //console.log('The menubutton was clicked.');
        props.onClick(e)
    }

    return (
        <div className={'menu-button-container' }
            onClick={ handleClick }>
            <div className={'menu-button-line menu-button-line-top ' + (props.open ? 'menu-button-line-top-open' : 'menu-button-line-top-close')}/>
            <div className={'menu-button-line ' + (props.open ? 'menu-button-line-middle-open' : 'menu-button-line-middle-close')}/>
            <div className={'menu-button-line menu-button-line-bottom ' + (props.open ? 'menu-button-line-bottom-open' : 'menu-button-line-bottom-closes')}/>
        </div>
    )
}

export default MenuButton