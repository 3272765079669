import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

import { LEVELS } from '../Common/Global'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { WORD_STATUS } from '../Common/Global'

import './StatsCategoryTile.css'

function StatsCategoryTile (props) {
    
    const replaceAt = (word, index, replacement) => {
        return word.substr(0, index) + replacement + word.substr(index + replacement.length)
    }

    const getDisplayWord = () => {
        let word = props.word
        console.log(props.status)
        console.log(props.level)
        if (props.status !== WORD_STATUS.Solved) {
                switch(props.level) {
                case LEVELS.FirstLetter:
                    word = replaceAt(word, 0, "_")
                    console.log(word)
                    break
                case LEVELS.LastLetter:
                    word = replaceAt(word, word.length - 1, "_")
                    break
                case LEVELS.MiddleLetter:
                    {
                        let charIdx = Math.floor(word.length / 2)
                        let middleChar = word.charAt(charIdx)
                        while (middleChar === ' ' && charIdx >= 0) {
                            charIdx = charIdx - 1
                            middleChar = word.charAt(charIdx)
                        }
                        word = replaceAt(word, charIdx, "_")
                        break
                    }
                case LEVELS.EntireWord:
                    let wordArr = word.split("")
                    for (var i=0; i < wordArr.length; i++) {
                        wordArr[i] = "_"
                    }
                    word = wordArr.join(" ")
                    break
                default:
                    break
            }
        }
        console.log(word)
        return word
    }

    return (
        <Card className='stats-cat-card'>
            <div className='stats-cat-card-content-media'>
                <CardMedia
                    className='stats-cat-card-content-image'
                    image= { process.env.PUBLIC_URL + props.image }
                    title="heart"
                />
            </div>
            <div className='stats-cat-card-content-details'>
                <CardContent className='stats-cat-card-content'>
                    <Typography variant="button" className={'stats-cat-card-content-status stats-cat-card-content-status-' + Object.keys(WORD_STATUS).find(k => WORD_STATUS[k] === props.status)}>
                        { i18next.t( props.status ) }
                    </Typography>
                    <Typography component="h5" variant="h5" className='stats-cat-card-content-word'>
                        { getDisplayWord() }
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        { i18next.t( props.level ) }
                    </Typography>
                    <Typography variant="overline" color="textSecondary" className='stats-cat-card-content-date'>
                        { props.lastPlayed }
                    </Typography>
                    <Typography variant="caption" className='stats-cat-card-content-time'>
                        { props.playTime + ' ' + i18next.t('seconds.label') }
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}

export default withTranslation()(StatsCategoryTile)
