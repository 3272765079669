export const gameData = [
    {
        category: {
            title: {
                he: "בַּבַּיִת",
                en: "Home"
            },
            value: "home",
            image: "/images/home/image.svg"
        },
        words: [
          {
            image: "bed.svg",
            lang: 
                {
                    he: {
                        word: "מיטה",
                        sound: "bed"
                    },
                    en: {
                        word: "Bed",
                        sound: "bed"
                    }
                }
            },
          {
            image: "couch.svg",
            lang: 
                {
                    he: {
                        word: "ספה",
                        sound: "couch"
                    } ,
                    en: {
                        word: "Couch",
                        sound: "couch"
                    }
                }
            },
          {
            image: "electric.svg",
            lang: 
                {
                    he: {
                        word: "מנורה",
                        sound: "electric"
                    },
                    en: {
                        word: "lamp",
                        sound: "lamp"
                    }
                }
            },
          {
            image: "buildings.svg",
            lang: 
                {
                    he: {
                        word: "וילון",
                        sound: "buildings"
                    },
                    en: {
                        word: "Curtain",
                        sound: "curtain"
                    }
                }
            },
          {
            image: "adornment.svg",
            lang: 
                {
                    he: {
                        word: "כרית",
                        sound: "adornment"
                    },
                    en: {
                        word: "Adornment",
                        sound: "adornment"
                    }
                }
            },
          {
            image: "archive.svg",
            lang: 
                {
                    he: {
                        word: "ארון",
                        sound: "archive"
                    },
                    en: {
                        word: "Archive",
                        sound: "archive"
                    }
                }
            },
          {
            image: "bed-2.svg",
            lang: 
                {
                    he: {
                        word: "מיטה",
                        sound: "bed"
                    },
                    en: {
                        word: "Bed",
                        sound: "bed"
                    }
                }
            },
          {
            image: "blossom.svg",
            lang: 
                {
                    he: {
                        word: "אגרטל",
                        sound: "blossom"
                    },
                    en: {
                        word: "Vaze",
                        sound: "vaze"
                    }
                }
            },
          {
            image: "image.svg",
            lang: 
                {
                    he: {
                        word: "תמונה",
                        sound: "image"
                    },
                    en: {
                        word: "Image",
                        sound: "image"
                    }
                }
            },
          {
            image: "framed.svg",
            lang: 
                {
                    he: {
                        word: "מראה",
                        sound: "framed"
                    },
                    en: {
                        word: "Mirror",
                        sound: "mirror"
                    }
                }
            },
          {
            image: "chair.svg",
            lang: 
                {
                    he: {
                        word: "כסא",
                        sound: "chair"
                    },
                    en: {
                        word: "Chair",
                        sound: "chair"
                    }
                }
            },
          {
            image: "circular-clock.svg",
            lang: 
                {
                    he: {
                        word: "שעון",
                        sound: "circular-clock"
                    },
                    en: {
                        word: "Clock",
                        sound: "clock"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "כֵּלִים", 
                en: "Tools"
            },
            value: "tools",
            image: "/images/tools/hammer.svg"
        },
        words: [
          {
            image: "scissors.svg",
            lang: 
                {
                    he: {
                        word: "מספרים",
                        sound: "scissors"
                    },
                    en: {
                        word: "Scissors",
                        sound: "scissors"
                    }
                }
            },
          {
            image: "measuring-tape.svg",
            lang: 
                {
                    he: {
                        word: "מטר",
                        sound: "measuring-tape"
                    },
                    en: {
                        word: "Measuring tape",
                        sound: "measuring-tape"
                    }
                }
            },
          {
            image: "pliers.svg",
            lang: 
                {
                    he: {
                        word: "צבת",
                        sound: "pliers"
                    },
                    en: {
                        word: "Pliers",
                        sound: "pliers"
                    }
                }
            },
          {
            image: "saw.svg",
            lang: 
                {
                    he: {
                        word: "מסור",
                        sound: "saw"
                    },
                    en: {
                        word: "Saw",
                        sound: "saw"
                    }
                }
            },
          {
            image: "hammer.svg",
            lang: 
                {
                    he: {
                        word: "פטיש",
                        sound: "hammer"
                    },
                    en: {
                        word: "Hammer",
                        sound: "hammer"
                    }
                }
            },
          {
            image: "shovel.svg",
            lang: 
                {
                    he: {
                        word: "את",
                        sound: "shovel"
                    },
                    en: {
                        word: "Shovel",
                        sound: "shovel"
                    }
                }
            },
          {
            image: "pencil.svg",
            lang: 
                {
                    he: {
                        word: "עפרון",
                        sound: "pencil"
                    },
                    en: {
                        word: "Pencil",
                        sound: "pencil"
                    }
                }
            },
          {
            image: "axe.svg",
            lang: 
                {
                    he: {
                        word: "גרזן",
                        sound: "axe"
                    },
                    en: {
                        word: "Axe",
                        sound: "axe"
                    }
                }
            },
          {
            image: "paintbrush.svg",
            lang: 
                {
                    he: {
                        word: "מברשת",
                        sound: "paintbrush"
                    },
                    en: {
                        word: "Paintbrush",
                        sound: "paintbrush"
                    }
                }
            },
          {
            image: "drill.svg",
            lang: 
                {
                    he: {
                        word: "מקדחה",
                        sound: "drill"
                    },
                    en: {
                        word: "Drill",
                        sound: "drill"
                    }
                }
            },
          {
            image: "screwdriver.svg",
            lang: 
                {
                    he: {
                        word: "מברג",
                        sound: "screwdriver"
                    },
                    en: {
                        word: "Screwdriver",
                        sound: "screwdriver"
                    }
                }
            },
          {
            image: "nail.svg",
            lang: 
                {
                    he: {
                        word: "מסמר",
                        sound: "nail"
                    },
                    en: {
                        word: "Nail",
                        sound: "nail"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "תַּחְבּוּרָה",
                en: "Transport"
            },
            value: "transport",
            image: "/images/transport/aeroplane.svg"
        },
        words: [
          {
            image: "racer-man.svg",
            lang: 
                {
                    he: {
                        word: "שוטר",
                        sound: "racer-man"
                    },
                    en: {
                        word: "Policeman",
                        sound: "policeman"
                    }
                }
            },
          {
            image: "concrete-mixer.svg",
            lang: 
                {
                    he: {
                        word: "מערבל  בטון",
                        sound: "concrete-mixer"
                    },
                    en: {
                        word: "Concrete mixer",
                        sound: "concrete-mixer"
                    }
                }
            },
          {
            image: "motorbike.svg",
            lang: 
                {
                    he: {
                        word: "אופנוע",
                        sound: "motorbike"
                    },
                    en: {
                        word: "Motorbike",
                        sound: "motorbike"
                    }
                }
            },
          {
            image: "helicopter.svg",
            lang: 
                {
                    he: {
                        word: "מסוק",
                        sound: "helicopter"
                    },
                    en: {
                        word: "Helicopter",
                        sound: "helicopter"
                    }
                }
            },
          {
            image: "sailboat.svg",
            lang: 
                {
                    he: {
                        word: "סירה",
                        sound: "sailboat"
                    },
                    en: {
                        word: "Sailboat",
                        sound: "sailboat"
                    }
                }
            },
          {
            image: "aeroplane.svg",
            lang: 
                {
                    he: {
                        word: "מטוס",
                        sound: "aeroplane"
                    },
                    en: {
                        word: "Aeroplane",
                        sound: "aeroplane"
                    }
                }
            },
          {
            image: "taxi.svg",
            lang: 
                {
                    he: {
                        word: "מונית",
                        sound: "taxi"
                    },
                    en: {
                        word: "Taxi",
                        sound: "taxi"
                    }
                }
            },
          {
            image: "garbage-truck.svg",
            lang: 
                {
                    he: {
                        word: "משאית  זבל",
                        sound: "garbage-truck"
                    },
                    en: {
                        word: "Garbage truck",
                        sound: "garbage-truck"
                    }
                }
            },
          {
            image: "loader.svg",
            lang: 
                {
                    he: {
                        word: "טרקטור",
                        sound: "loader"
                    },
                    en: {
                        word: "Tractor",
                        sound: "tractor"
                    }
                }
            },
          {
            image: "racer-woman.svg",
            lang: 
                {
                    he: {
                        word: "שוטרת",
                        sound: "racer-woman"
                    },
                    en: {
                        word: "Policewoman",
                        sound: "policewoman"
                    }
                }
            },
          {
            image: "truck.svg",
            lang: 
                {
                    he: {
                        word: "משאית",
                        sound: "truck"
                    },
                    en: {
                        word: "Truck",
                        sound: "truck"
                    }
                }
            },
          {
            image: "bus.svg",
            lang: 
                {
                    he: {
                        word: "אוטובוס",
                        sound: "bus"
                    },
                    en: {
                        word: "Bus",
                        sound: "bus"
                    }
                }
            },
          {
            image: "hot-air-balloon.svg",
            lang: 
                {
                    he: {
                        word: "כדור   פורח",
                        sound: "hot-air-balloon"
                    },
                    en: {
                        word: "Hot air balloon",
                        sound: "hot-air-ballon"
                    }
                }
            },
          {
            image: "traffic-light.svg",
            lang: 
                {
                    he: {
                        word: "רמזור",
                        sound: "traffic-light"
                    },
                    en: {
                        word: "Traffic light",
                        sound: "traffic-light"
                    }
                }
            },
          {
            image: "bicycle.svg",
            lang: 
                {
                    he: {
                        word: "אופניים",
                        sound: "bicycle"
                    },
                    en: {
                        word: "Bicycle",
                        sound: "bicycle"
                    }
                }
            },
          {
            image: "helmet.svg",
            lang: 
                {
                    he: {
                        word: "קסדה",
                        sound: "helmet"
                    },
                    en: {
                        word: "Helmet",
                        sound: "helmet"
                    }
                }
            },
          {
            image: "car.svg",
            lang: 
                {
                    he: {
                        word: "מכונית",
                        sound: "car"
                    },
                    en: {
                        word: "Car",
                        sound: "car"
                    }
                }
            },
          {
            image: "medal.svg",
            lang: 
                {
                    he: {
                        word: "מדליה",
                        sound: "medal"
                    },
                    en: {
                        word: "Medal",
                        sound: "medal"
                    }
                }
            },
          {
            image: "zeppelin.svg",
            lang: 
                {
                    he: {
                        word: "צפלין",
                        sound: "zeppelin"
                    },
                    en: {
                        word: "Zeppelin",
                        sound: "zeppelin"
                    }
                }
            },
          {
            image: "cup.svg",
            lang: 
                {
                    he: {
                        word: "גביע",
                        sound: "cup"
                    },
                    en: {
                        word: "Cup",
                        sound: "cup"
                    }
                }
            },
          {
            image: "van.svg",
            lang: 
                {
                    he: {
                        word: "אוטו   גלידה",
                        sound: "van"
                    },
                    en: {
                        word: "Ice cream truck",
                        sound: "ice-cream-truck"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "אַהֲבָה",
                en: "Love"
            },
            value: "love",
            image: "/images/love/heart.svg"
        },
        words: [
          {
            image: "fairy.svg",
            lang: 
                {
                    he: {
                        word: "פיה",
                        sound: "fairy"
                    },
                    en: {
                        word: "Fairy",
                        sound: "fairy"
                    }
                }
            },
          {
            image: "key.svg",
            lang: 
                {
                    he: {
                        word: "מפתח",
                        sound: "key"
                    },
                    en: {
                        word: "Key",
                        sound: "key"
                    }
                }
            },
          {
            image: "suit.svg",
            lang: 
                {
                    he: {
                        word: "חליפה",
                        sound: "suit"
                    },
                    en: {
                        word: "Suit",
                        sound: "suit"
                    }
                }
            },
          {
            image: "unicorn.svg",
            lang: 
                {
                    he: {
                        word: "חדקרן",
                        sound: "unicorn"
                    },
                    en: {
                        word: "Unicorn",
                        sound: "unicorn"
                    }
                }
            },
          {
            image: "rainbow.svg",
            lang: 
                {
                    he: {
                        word: "קשת    בענן",
                        sound: "rainbow"
                    },
                    en: {
                        word: "Rainbow",
                        sound: "rainbow"
                    }
                }
            },
          {
            image: "engagement-ring.svg",
            lang: 
                {
                    he: {
                        word: "טבעת",
                        sound: "engagement-ring"
                    },
                    en: {
                        word: "Ring",
                        sound: "ring"
                    }
                }
            },
          {
            image: "wedding-cake.svg",
            lang: 
                {
                    he: {
                        word: "עוגה",
                        sound: "wedding-cake"
                    },
                    en: {
                        word: "Cake",
                        sound: "cake"
                    }
                }
            },
          {
            image: "sunglasses.svg",
            lang: 
                {
                    he: {
                        word: "משקפים",
                        sound: "sunglasses"
                    },
                    en: {
                        word: "Sun glasses",
                        sound: "sunglasses"
                    }
                }
            },
          {
            image: "teddy-bear.svg",
            lang: 
                {
                    he: {
                        word: "דובי",
                        sound: "teddy-bear"
                    },
                    en: {
                        word: "Teddy bear",
                        sound: "teddy-bear"
                    }
                }
            },
          {
            image: "romantic-music.svg",
            lang: 
                {
                    he: {
                        word: "מוסיקה",
                        sound: "romantic-music"
                    },
                    en: {
                        word: "Music",
                        sound: "music"
                    }
                }
            },
          {
            image: "balloon.svg",
            lang: 
                {
                    he: {
                        word: "בלון",
                        sound: "balloon"
                    },
                    en: {
                        word: "Balloon",
                        sound: "balloon"
                    }
                }
            },
          {
            image: "heart.svg",
            lang: 
                {
                    he: {
                        word: "לב",
                        sound: "heart"
                    },
                    en: {
                        word: "Heart",
                        sound: "heart"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "מַדָּע וְחָלָל",
                en: "Science"
            },
            value: "science",
            image: "/images/science/planet-earth.svg"
        },
        words: [
          {
            image: "jupiter.svg",
            lang: 
                {
                    he: {
                        word: "צדק",
                        sound: "jupiter"
                    },
                    en: {
                        word: "Jupiter",
                        sound: "jupiter"
                    }
                }
            },
          {
            image: "mars.svg",
            lang: 
                {
                    he: {
                        word: "מאדים",
                        sound: "mars"
                    },
                    en: {
                        word: "Mars",
                        sound: "mars"
                    }
                }
            },
          {
            image: "magnet.svg",
            lang: 
                {
                    he: {
                        word: "מגנט",
                        sound: "magnet"
                    },
                    en: {
                        word: "Magnet",
                        sound: "magnet"
                    }
                }
            },
          {
            image: "venus.svg",
            lang: 
                {
                    he: {
                        word: "נגה",
                        sound: "venus"
                    },
                    en: {
                        word: "Venus",
                        sound: "venus"
                    }
                }
            },
          {
            image: "uranus.svg",
            lang: 
                {
                    he: {
                        word: "אורון",
                        sound: "uranus"
                    },
                    en: {
                        word: "Uranus",
                        sound: "uranus"
                    }
                }
            },
          {
            image: "shooting-star.svg",
            lang: 
                {
                    he: {
                        word: "כוכב   נופל",
                        sound: "shooting-star"
                    },
                    en: {
                        word: "Shooting star",
                        sound: "shooting-star"
                    }
                }
            },
          {
            image: "saturn.svg",
            lang: 
                {
                    he: {
                        word: "שבתאי",
                        sound: "saturn"
                    },
                    en: {
                        word: "Saturn",
                        sound: "saturn"
                    }
                }
            },
          {
            image: "mercury.svg",
            lang: 
                {
                    he: {
                        word: "חמה",
                        sound: "mercury"
                    },
                    en: {
                        word: "Mercury",
                        sound: "mercury"
                    }
                }
            },
          {
            image: "pluto.svg",
            lang: 
                {
                    he: {
                        word: "פלוטו",
                        sound: "pluto"
                    },
                    en: {
                        word: "Pluto",
                        sound: "pluto"
                    }
                }
            },
          {
            image: "neptune.svg",
            lang: 
                {
                    he: {
                        word: "רהב",
                        sound: "neptune"
                    },
                    en: {
                        word: "Neptune",
                        sound: "neptune"
                    }
                }
            },
          {
            image: "test-tube.svg",
            lang: 
                {
                    he: {
                        word: "מבחנה",
                        sound: "test-tube"
                    },
                    en: {
                        word: "Test tube",
                        sound: "test-tube"
                    }
                }
            },
          {
            image: "robot.svg",
            lang: 
                {
                    he: {
                        word: "רובוט",
                        sound: "robot"
                    },
                    en: {
                        word: "Robot",
                        sound: "robot"
                    }
                }
            },
          {
            image: "alien.svg",
            lang: 
                {
                    he: {
                        word: "חיזר",
                        sound: "alien"
                    },
                    en: {
                        word: "Alien",
                        sound: "alien"
                    }
                }
            },
          {
            image: "rocket.svg",
            lang: 
                {
                    he: {
                        word: "חללית",
                        sound: "rocket"
                    },
                    en: {
                        word: "Rocket",
                        sound: "rocket"
                    }
                }
            },
          {
            image: "planet-earth.svg",
            lang: 
                {
                    he: {
                        word: "כדור   הארץ",
                        sound: "planet-earth"
                    },
                    en: {
                        word: "Earth",
                        sound: "earth"
                    }
                }
            },
          {
            image: "comet.svg",
            lang: 
                {
                    he: {
                        word: "שביט",
                        sound: "comet"
                    },
                    en: {
                        word: "Comet",
                        sound: "comet"
                    }
                }
            },
          {
            image: "moon.svg",
            lang: 
                {
                    he: {
                        word: "ירח",
                        sound: "moon"
                    },
                    en: {
                        word: "Moon",
                        sound: "moon"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "אַגָּדוֹת",
                en: "Fairy tale"
            },
            value: "fairy-tale",
            image: "/images/fairy-tale/frog.svg"
        },
        words: [
          {
            image: "knight.svg",
            lang: 
                {
                    he: {
                        word: "אביר",
                        sound: "knight"
                    },
                    en: {
                        word: "Knight",
                        sound: "knight"
                    }
                }
            },
          {
            image: "armor.svg",
            lang: 
                {
                    he: {
                        word: "שריון",
                        sound: "armor"
                    },
                    en: {
                        word: "Armor",
                        sound: "armor"
                    }
                }
            },
          {
            image: "magic-lamp.svg",
            lang: 
                {
                    he: {
                        word: "מנורת  קסם",
                        sound: "magic-lamp"
                    },
                    en: {
                        word: "Magic lamp",
                        sound: "magic-lamp"
                    }
                }
            },
          {
            image: "mermaid.svg",
            lang: 
                {
                    he: {
                        word: "בת ים",
                        sound: "mermaid"
                    },
                    en: {
                        word: "Mermaid",
                        sound: "mermaid"
                    }
                }
            },
          {
            image: "dragon.svg",
            lang: 
                {
                    he: {
                        word: "דרקון",
                        sound: "dragon"
                    },
                    en: {
                        word: "Dragon",
                        sound: "dragon"
                    }
                }
            },
          {
            image: "wizard.svg",
            lang: 
                {
                    he: {
                        word: "קוסם",
                        sound: "wizard"
                    },
                    en: {
                        word: "Wizard",
                        sound: "wizard"
                    }
                }
            },
          {
            image: "shield.svg",
            lang: 
                {
                    he: {
                        word: "מגן",
                        sound: "shield"
                    },
                    en: {
                        word: "Shield",
                        sound: "shield"
                    }
                }
            },
          {
            image: "castle.svg",
            lang: 
                {
                    he: {
                        word: "טירה",
                        sound: "castle"
                    },
                    en: {
                        word: "Castle",
                        sound: "castle"
                    }
                }
            },
          {
            image: "flag.svg",
            lang: 
                {
                    he: {
                        word: "דגל",
                        sound: "flag"
                    },
                    en: {
                        word: "Flag",
                        sound: "flag"
                    }
                }
            },
          {
            image: "unicorn.svg",
            lang: 
                {
                    he: {
                        word: "חדקרן",
                        sound: "unicorn"
                    },
                    en: {
                        word: "Unicorn",
                        sound: "unicorn"
                    }
                }
            },
          {
            image: "queen.svg",
            lang: 
                {
                    he: {
                        word: "מלכה",
                        sound: "queen"
                    },
                    en: {
                        word: "Queen",
                        sound: "queen"
                    }
                }
            },
          {
            image: "king.svg",
            lang: 
                {
                    he: {
                        word: "מלך",
                        sound: "king"
                    },
                    en: {
                        word: "King",
                        sound: "king"
                    }
                }
            },
          {
            image: "rainbow.svg",
            lang: 
                {
                    he: {
                        word: "קשת     בענן",
                        sound: "rainbow"
                    },
                    en: {
                        word: "Rainbow",
                        sound: "rainbow"
                    }
                }
            },
          {
            image: "crown.svg",
            lang: 
                {
                    he: {
                        word: "כתר",
                        sound: "crown"
                    },
                    en: {
                        word: "Crown",
                        sound: "crown"
                    }
                }
            },
          {
            image: "frog.svg",
            lang: 
                {
                    he: {
                        word: "צפרדע",
                        sound: "frog"
                    },
                    en: {
                        word: "Frog",
                        sound: "frog"
                    }
                }
            },
          {
            image: "palace.svg",
            lang: 
                {
                    he: {
                        word: "ארמון",
                        sound: "palace"
                    },
                    en: {
                        word: "Palace",
                        sound: "palace"
                    }
                }
            },
          {
            image: "princess.svg",
            lang: 
                {
                    he: {
                        word: "נסיכה",
                        sound: "princess"
                    },
                    en: {
                        word: "Princess",
                        sound: "princess"
                    }
                }
            },
          {
            image: "sword.svg",
            lang: 
                {
                    he: {
                        word: "חרב",
                        sound: "sword"
                    },
                    en: {
                        word: "Sword",
                        sound: "sword"
                    }
                }
          }
        ]
    },
    {
        category: {
            title: {
                he: "בַּעֲלֵי חַיִּים",
                en: "Animals"
            },
            value: "animals",
            image: "/images/animals/giraffe.svg"
        },
        words: [
        {
            image: "poo.svg",
            lang: 
                {
                    he: {
                        word: "קקי",
                        sound: "poo"
                    },
                    en: {
                        word: "Poo",
                        sound: "poo"
                    }
                }
        },
        {
            image: "raccoon.svg",
            lang: 
                {
                    he: {
                        word: "רקון",
                        sound: "raccoon"
                    },
                    en: {
                        word: "Raccoon",
                        sound: "raccoon"
                    }
                }
            },
        {
            image: "butterfly.svg",
            lang: 
                {
                    he: {
                        word: "פרפר",
                        sound: "butterfly"
                    },
                    en: {
                        word: "Butterfly",
                        sound: "butterfly"
                    }
                }
            },
        {
            image: "gorilla.svg",
            lang: 
                {
                    he: {
                        word: "גורילה",
                        sound: "gorilla"
                    },
                    en: {
                        word: "Gorilla",
                        sound: "gorilla"
                    }
                }
            },
        {
            image: "toucan.svg",
            lang: 
                {
                    he: {
                        word: "טוקן",
                        sound: "toucan"
                    },
                    en: {
                        word: "Toucan",
                        sound: "toucan"
                    }
                }
            },
        {
            image: "cow.svg",
            lang: 
                {
                    he: {
                        word: "פרה",
                        sound: "cow"
                    },
                    en: {
                        word: "Cow",
                        sound: "cow"
                    }
                }
            },
        {
            image: "lobster.svg",
            lang: 
                {
                    he: {
                        word: "לובסטר",
                        sound: "lobster"
                    },
                    en: {
                        word: "Lobster",
                        sound: "lobster"
                    }
                }
            },
        {
            image: "pet-shop.svg",
            lang: 
                {
                    he: {
                        word: "חנות   חיות",
                        sound: "pet-shop"
                    },
                    en: {
                        word: "Pet shop",
                        sound: "pet-shop"
                    }
                }
            },
        {
            image: "sheep.svg",
            lang: 
                {
                    he: {
                        word: "כבשה",
                        sound: "sheep"
                    },
                    en: {
                        word: "Sheep",
                        sound: "sheep"
                    }
                }
            },
        {
            image: "pet-shampoo.svg",
            lang: 
                {
                    he: {
                        word: "שמפו",
                        sound: "pet-shampoo"
                    },
                    en: {
                        word: "Shampoo",
                        sound: "shampoo"
                    }
                }
            },
        {
            image: "ball-of-wool.svg",
            lang: 
                {
                    he: {
                        word: "כדור   צמר",
                        sound: "ball-of-wool"
                    },
                    en: {
                        word: "Wool ball",
                        sound: "wool-ball"
                    }
                }
            },
        {
            image: "chicken.svg",
            lang: 
                {
                    he: {
                        word: "תרנגולת",
                        sound: "chicken"
                    },
                    en: {
                        word: "Chicken",
                        sound: "chicken"
                    }
                }
            },
        {
            image: "swan.svg",
            lang: 
                {
                    he: {
                        word: "ברבור",
                        sound: "swan"
                    },
                    en: {
                        word: "Swan",
                        sound: "swan"
                    }
                }
            },
        {
            image: "pet-food-1.svg",
            lang: 
                {
                    he: {
                        word: "אוכל",
                        sound: "pet-food-1"
                    },
                    en: {
                        word: "Pet food",
                        sound: "pet-food"
                    }
                }
            },
        {
            image: "lion.svg",
            lang: 
                {
                    he: {
                        word: "אריה",
                        sound: "lion"
                    },
                    en: {
                        word: "Lion",
                        sound: "lion"
                    }
                }
            },
        {
            image: "diploma.svg",
            lang: 
                {
                    he: {
                        word: "תעודה",
                        sound: "diploma"
                    },
                    en: {
                        word: "Diploma",
                        sound: "diploma"
                    }
                }
            },
        {
            image: "spider.svg",
            lang: 
                {
                    he: {
                        word: "עכביש",
                        sound: "spider"
                    },
                    en: {
                        word: "Spider",
                        sound: "spider"
                    }
                }
            },
        {
            image: "turtle.svg",
            lang: 
                {
                    he: {
                        word: "צב",
                        sound: "turtle"
                    },
                    en: {
                        word: "Turtle",
                        sound: "turtle"
                    }
                }
            },
        {
            image: "rabbit.svg",
            lang: 
                {
                    he: {
                        word: "ארנבת",
                        sound: "rabbit"
                    },
                    en: {
                        word: "Rabbit",
                        sound: "rabbit"
                    }
                }
            },
        {
            image: "horse.svg",
            lang: 
                {
                    he: {
                        word: "סוס",
                        sound: "horse"
                    },
                    en: {
                        word: "Horse",
                        sound: "horse"
                    }
                }
            },
        {
            image: "cage.svg",
            lang: 
                {
                    he: {
                        word: "כלוב",
                        sound: "cage"
                    },
                    en: {
                        word: "Cage",
                        sound: "cage"
                    }
                }
            },
        {
            image: "parrot.svg",
            lang: 
                {
                    he: {
                        word: "תוכי",
                        sound: "parrot"
                    },
                    en: {
                        word: "Parrot",
                        sound: "parrot"
                    }
                }
            },
        {
            image: "dog.svg",
            lang: 
                {
                    he: {
                        word: "כלב",
                        sound: "dog"
                    },
                    en: {
                        word: "Dog",
                        sound: "dog"
                    }
                }
            },
        {
            image: "collar.svg",
            lang: 
                {
                    he: {
                        word: "קולר",
                        sound: "collar"
                    },
                    en: {
                        word: "Collar",
                        sound: "collar"
                    }
                }
            },
        {
            image: "goat.svg",
            lang: 
                {
                    he: {
                        word: "עז",
                        sound: "goat"
                    },
                    en: {
                        word: "Goat",
                        sound: "goat"
                    }
                }
            },
        {
            image: "snail.svg",
            lang: 
                {
                    he: {
                        word: "חילזון",
                        sound: "snail"
                    },
                    en: {
                        word: "Snail",
                        sound: "snail"
                    }
                }
            },
        {
            image: "bear.svg",
            lang: 
                {
                    he: {
                        word: "דב",
                        sound: "bear"
                    },
                    en: {
                        word: "Bear",
                        sound: "bear"
                    }
                }
            },
        {
            image: "injection.svg",
            lang: 
                {
                    he: {
                        word: "זריקה",
                        sound: "injection"
                    },
                    en: {
                        word: "Injection",
                        sound: "injection"
                    }
                }
            },
        {
            image: "award.svg",
            lang: 
                {
                    he: {
                        word: "פרס",
                        sound: "award"
                    },
                    en: {
                        word: "Award",
                        sound: "award"
                    }
                }
            },
        {
            image: "pig.svg",
            lang: 
                {
                    he: {
                        word: "חזיר",
                        sound: "pig"
                    },
                    en: {
                        word: "Pig",
                        sound: "pig"
                    }
                }
            },
        {
            image: "cat.svg",
            lang: 
                {
                    he: {
                        word: "חתול",
                        sound: "cat"
                    },
                    en: {
                        word: "Cat",
                        sound: "cat"
                    }
                }
            },
        {
            image: "fox.svg",
            lang: 
                {
                    he: {
                        word: "שועל",
                        sound: "fox"
                    },
                    en: {
                        word: "Fox",
                        sound: "fox"
                    }
                }
            },
        {
            image: "giraffe.svg",
            lang: 
                {
                    he: {
                        word: "גירפה",
                        sound: "giraffe"
                    },
                    en: {
                        word: "Giraffe",
                        sound: "giraffe"
                    }
                }
            },
        {
            image: "dove.svg",
            lang: 
                {
                    he: {
                        word: "יונה",
                        sound: "dove"
                    },
                    en: {
                        word: "Dove",
                        sound: "dove"
                    }
                }
            },
        {
            image: "leash.svg",
            lang: 
                {
                    he: {
                        word: "רצועה",
                        sound: "leash"
                    },
                    en: {
                        word: "Leash",
                        sound: "leash"
                    }
                }
            },
        {
            image: "pet-food.svg",
            lang: 
                {
                    he: {
                        word: "אוכל",
                        sound: "pet-food"
                    },
                    en: {
                        word: "Pet food",
                        sound: "pet-food"
                    }
                }
            },
        {
            image: "canary.svg",
            lang: 
                {
                    he: {
                        word: "כנרית",
                        sound: "canary"
                    },
                    en: {
                        word: "Canary",
                        sound: "canary"
                    }
                }
            },
        {
            image: "squirrel.svg",
            lang: 
                {
                    he: {
                        word: "סנאי",
                        sound: "squirrel"
                    },
                    en: {
                        word: "Squirrel",
                        sound: "squirrel"
                    }
                }
            },
        {
            image: "kennel.svg",
            lang: 
                {
                    he: {
                        word: "מלונה",
                        sound: "kennel"
                    },
                    en: {
                        word: "Kennel",
                        sound: "kennel"
                    }
                }
            },
        {
            image: "grass.svg",
            lang: 
                {
                    he: {
                        word: "דשא",
                        sound: "grass"
                    },
                    en: {
                        word: "Grass",
                        sound: "grass"
                    }
                }
            },
        {
            image: "comb.svg",
            lang: 
                {
                    he: {
                        word: "מסרק",
                        sound: "comb"
                    },
                    en: {
                        word: "Comb",
                        sound: "comb"
                    }
                }
            },
        {
            image: "hamster.svg",
            lang: 
                {
                    he: {
                        word: "אוגר",
                        sound: "hamster"
                    },
                    en: {
                        word: "Hamster",
                        sound: "hamster"
                    }
                }
            },
        {
            image: "wolf.svg",
            lang: 
                {
                    he: {
                        word: "זאב",
                        sound: "wolf"
                    },
                    en: {
                        word: "Wolf",
                        sound: "wolf"
                    }
                }
        }
        ]
    },
    {
        category: {
            title: {
                he: "רְפוּאָה",
                en: "Medicine"
            },
            value: "medicine",
            image: "/images/medicine/hospital.svg"
        },
        words: [
        {
            image: "doctor-woman.svg",
            lang: 
                {
                    he: {
                        word: "רופאה",
                        sound: "doctor-woman"
                    },
                    en: {
                        word: "Doctor",
                        sound: "doctor"
                    }
                }
            },
        {
            image: "kidneys.svg",
            lang: 
                {
                    he: {
                        word: "כליות",
                        sound: "kidneys"
                    },
                    en: {
                        word: "Kidneys",
                        sound: "kidneys"
                    }
                }
            },
        {
            image: "ambulance-2.svg",
            lang: 
                {
                    he: {
                        word: "מסוק",
                        sound: "ambulance-2"
                    },
                    en: {
                        word: "Helicopter",
                        sound: "helicopter"
                    }
                }
            },
        {
            image: "doctor.svg",
            lang: 
                {
                    he: {
                        word: "רופא",
                        sound: "doctor"
                    },
                    en: {
                        word: "Doctor",
                        sound: "doctor"
                    }
                }
            },
        {
            image: "liver.svg",
            lang: 
                {
                    he: {
                        word: "כבד",
                        sound: "liver"
                    },
                    en: {
                        word: "Liver",
                        sound: "liver"
                    }
                }
            },
        {
            image: "nose.svg",
            lang: 
                {
                    he: {
                        word: "אף",
                        sound: "nose"
                    },
                    en: {
                        word: "Nose",
                        sound: "nose"
                    }
                }
            },
        {
            image: "brain.svg",
            lang: 
                {
                    he: {
                        word: "מוח",
                        sound: "brain"
                    },
                    en: {
                        word: "Brain",
                        sound: "brain"
                    }
                }
            },
        {
            image: "ambulance.svg",
            lang: 
                {
                    he: {
                        word: "אמבולנס",
                        sound: "ambulance"
                    },
                    en: {
                        word: "Ambulance",
                        sound: "ambulance"
                    }
                }
            },
        {
            image: "hospital.svg",
            lang: 
                {
                    he: {
                        word: "בית    חולים",
                        sound: "hospital"
                    },
                    en: {
                        word: "Hospital",
                        sound: "hospital"
                    }
                }
            },
        {
            image: "stomach.svg",
            lang: 
                {
                    he: {
                        word: "קיבה",
                        sound: "stomach"
                    },
                    en: {
                        word: "Stomach",
                        sound: "stomach"
                    }
                }
            },
        {
            image: "injection.svg",
            lang: 
                {
                    he: {
                        word: "זריקה",
                        sound: "injection"
                    },
                    en: {
                        word: "Injection",
                        sound: "injection"
                    }
                }
            },
        {
            image: "crutches.svg",
            lang: 
                {
                    he: {
                        word: "קביים",
                        sound: "crutches"
                    },
                    en: {
                        word: "Crutches",
                        sound: "crutches"
                    }
                }
            },
        {
            image: "lungs.svg",
            lang: 
                {
                    he: {
                        word: "ריאות",
                        sound: "lungs"
                    },
                    en: {
                        word: "Lungs",
                        sound: "lungs"
                    }
                }
            },
        {
            image: "eye.svg",
            lang: 
                {
                    he: {
                        word: "עין",
                        sound: "eye"
                    },
                    en: {
                        word: "Eye",
                        sound: "eye"
                    }
                }
            },
        {
            image: "pill.svg",
            lang: 
                {
                    he: {
                        word: "כדור",
                        sound: "pill"
                    },
                    en: {
                        word: "Pill",
                        sound: "pill"
                    }
                }
            },
        {
            image: "cough-syrup.svg",
            lang: 
                {
                    he: {
                        word: "תרופה",
                        sound: "cough-syrup"
                    },
                    en: {
                        word: "Cough syrup",
                        sound: "cough-syrup"
                    }
                }
            },
        {
            image: "ear.svg",
            lang: 
                {
                    he: {
                        word: "אוזן",
                        sound: "ear"
                    },
                    en: {
                        word: "Ear",
                        sound: "ear"
                    }
                }
            },
        {
            image: "heat.svg",
            lang: 
                {
                    he: {
                        word: "חום",
                        sound: "heat"
                    },
                    en: {
                        word: "Heat",
                        sound: "heat"
                    }
                }
            },
        {
            image: "heart.svg",
            lang: 
                {
                    he: {
                        word: "לב",
                        sound: "heart"
                    },
                    en: {
                        word: "Heart",
                        sound: "heart§"
                    }
                }
        }
        ]
    },
    {
        category: {
            title: {
                he: "אֹכֶל",
                en: "Food"
            },
            value: "food",
            image: "/images/food/toast.svg"
        },
        words: [
        {
            image: "eggs.svg",
            lang: 
                {
                    he: {
                        word: "ביצים",
                        sound: "eggs"
                    },
                    en: {
                        word: "Eggs",
                        sound: "eggs"
                    }
                }
            },
        {
            image: "tomato.svg",
            lang: 
                {
                    he: {
                        word: "עגבניה",
                        sound: "tomato"
                    },
                    en: {
                        word: "Tomato",
                        sound: "tomato"
                    }
                }
            },
        {
            image: "raspberries.svg",
            lang: 
                {
                    he: {
                        word: "פטל",
                        sound: "raspberries"
                    },
                    en: {
                        word: "Raspberries",
                        sound: "raspberries"
                    }
                }
            },
        {
            image: "onion.svg",
            lang: 
                {
                    he: {
                        word: "בצל",
                        sound: "onion"
                    },
                    en: {
                        word: "Onion",
                        sound: "onion"
                    }
                }
            },
        {
            image: "mango.svg",
            lang: 
                {
                    he: {
                        word: "מנגו",
                        sound: "mango"
                    },
                    en: {
                        word: "Mango",
                        sound: "mango"
                    }
                }
            },
        {
            image: "bell-pepper.svg",
            lang: 
                {
                    he: {
                        word: "גמבה",
                        sound: "bell-pepper"
                    },
                    en: {
                        word: "Bell pepper",
                        sound: "bell-pepper"
                    }
                }
            },
        {
            image: "steak.svg",
            lang: 
                {
                    he: {
                        word: "סטייק",
                        sound: "steak"
                    },
                    en: {
                        word: "Steak",
                        sound: "steak"
                    }
                }
        },
        {
            image: "pineapple.svg",
            lang: 
                {
                    he: {
                        word: "אננס",
                        sound: "pineapple"
                    },
                    en: {
                        word: "Pineapple",
                        sound: "pineapple"
                    }
                }
            },
        {
            image: "carrot.svg",
            lang: 
                {
                    he: {
                        word: "גזר",
                        sound: "carrot"
                    },
                    en: {
                        word: "Carrot",
                        sound: "carrot"
                    }
                }
            },
        {
            image: "beet.svg",
            lang: 
                {
                    he: {
                        word: "סלק",
                        sound: "beet"
                    },
                    en: {
                        word: "Beet",
                        sound: "beet"
                    }
                }
            },
        {
            image: "mushrooms.svg",
            lang: 
                {
                    he: {
                        word: "פטריות",
                        sound: "mushrooms"
                    },
                    en: {
                        word: "Mushrooms",
                        sound: "mushrooms"
                    }
                }
            },
        {
            image: "potato.svg",
            lang: 
                {
                    he: {
                        word: "תפוח   אדמה",
                        sound: "potato"
                    },
                    en: {
                        word: "Potato",
                        sound: "potato"
                    }
                }
            },
        {
            image: "corn.svg",
            lang: 
                {
                    he: {
                        word: "תירס",
                        sound: "corn"
                    },
                    en: {
                        word: "Corn",
                        sound: "corn"
                    }
                }
            },
        {
            image: "bread.svg",
            lang: 
                {
                    he: {
                        word: "לחם",
                        sound: "bread"
                    },
                    en: {
                        word: "Bread",
                        sound: "bread"
                    }
                }
            },
        {
            image: "peach.svg",
            lang: 
                {
                    he: {
                        word: "אפרסק",
                        sound: "peach"
                    },
                    en: {
                        word: "Peach",
                        sound: "peach"
                    }
                }
            },
        {
            image: "olives.svg",
            lang: 
                {
                    he: {
                        word: "זיתים",
                        sound: "olives"
                    },
                    en: {
                        word: "Olives",
                        sound: "olives"
                    }
                }
            },
        {
            image: "bread-1.svg",
            lang: 
                {
                    he: {
                        word: "לחם",
                        sound: "bread-1"
                    },
                    en: {
                        word: "Bread",
                        sound: "bread"
                    }
                }
            },
        {
            image: "broccoli.svg",
            lang: 
                {
                    he: {
                        word: "ברוקולי",
                        sound: "broccoli"
                    },
                    en: {
                        word: "Broccoli",
                        sound: "broccoli"
                    }
                }
            },
        {
            image: "fish.svg",
            lang: 
                {
                    he: {
                        word: "דג",
                        sound: "fish"
                    },
                    en: {
                        word: "Fish",
                        sound: "fish"
                    }
                }
            },
        {
            image: "cucumbers.svg",
            lang: 
                {
                    he: {
                        word: "מלפפון",
                        sound: "cucumbers"
                    },
                    en: {
                        word: "Cucumbers",
                        sound: "cucumbers"
                    }
                }
            },
        {
            image: "kiwi.svg",
            lang: 
                {
                    he: {
                        word: "קיוי",
                        sound: "kiwi"
                    },
                    en: {
                        word: "Kiwi",
                        sound: "kiwi"
                    }
                }
            },
        {
            image: "brussels-sprouts.svg",
            lang: 
                {
                    he: {
                        word: "כרוב",
                        sound: "brussels-sprouts"
                    },
                    en: {
                        word: "Brussels sprouts",
                        sound: "brussels-sprouts"
                    }
                }
            },
        {
            image: "toast.svg",
            lang: 
                {
                    he: {
                        word: "טוסט",
                        sound: "toast"
                    },
                    en: {
                        word: "Toast",
                        sound: "toast"
                    }
                }
            },
        {
            image: "sausage-1.svg",
            lang: 
                {
                    he: {
                        word: "נקניקיה",
                        sound: "sausage-1"
                    },
                    en: {
                        word: "Sausage",
                        sound: "sausage"
                    }
                }
            },
        {
            image: "radish.svg",
            lang: 
                {
                    he: {
                        word: "צנון",
                        sound: "radish"
                    },
                    en: {
                        word: "radish",
                        sound: "radish"
                    }
                }
            },
        {
            image: "pumpkin.svg",
            lang: 
                {
                    he: {
                        word: "דלעת",
                        sound: "pumpkin"
                    },
                    en: {
                        word: "Pumpkin",
                        sound: "pumpkin"
                    }
                }
            },
        {
            image: "sausage-2.svg",
            lang: 
                {
                    he: {
                        word: "נקניק",
                        sound: "sausage-2"
                    },
                    en: {
                        word: "Sausage",
                        sound: "sausage"
                    }
                }
            },
        {
            image: "cabbage-1.svg",
            lang: 
                {
                    he: {
                        word: "כרוב",
                        sound: "cabbage-1"
                    },
                    en: {
                        word: "Cabbage",
                        sound: "cabbage"
                    }
                }
            },
        {
            image: "hot-pepper.svg",
            lang: 
                {
                    he: {
                        word: "פלפל   חריף",
                        sound: "hot-pepper"
                    },
                    en: {
                        word: "Hot pepper",
                        sound: "hot-pepper"
                    }
                }
            },
        {
            image: "cabbage.svg",
            lang: 
                {
                    he: {
                        word: "כרוב",
                        sound: "cabbage"
                    },
                    en: {
                        word: "Cabbage",
                        sound: "cabbage"
                    }
                }
            },
        {
            image: "strawberry.svg",
            lang: 
                {
                    he: {
                        word: "תות",
                        sound: "strawberry"
                    },
                    en: {
                        word: "Strawberry",
                        sound: "strawberry"
                    }
                }
            },
        {
            image: "eggplant.svg",
            lang: 
                {
                    he: {
                        word: "חציל",
                        sound: "eggplant"
                    },
                    en: {
                        word: "Eggplant",
                        sound: "eggplant"
                    }
                }
            },
        {
            image: "banana.svg",
            lang: 
                {
                    he: {
                        word: "בננה",
                        sound: "banana"
                    },
                    en: {
                        word: "Banana",
                        sound: "banana"
                    }
                }
            },
        {
            image: "lemon.svg",
            lang: 
                {
                    he: {
                        word: "לימון",
                        sound: "lemon"
                    },
                    en: {
                        word: "Lemon",
                        sound: "lemon"
                    }
                }
            },
        {
            image: "peas.svg",
            lang: 
                {
                    he: {
                        word: "אפונה",
                        sound: "peas"
                    },
                    en: {
                        word: "Peas",
                        sound: "peas"
                    }
                }
            },
        {
            image: "pear.svg",
            lang: 
                {
                    he: {
                        word: "אגס",
                        sound: "pear"
                    },
                    en: {
                        word: "Pear",
                        sound: "pear"
                    }
                }
            },
        {
            image: "avocado.svg",
            lang: 
                {
                    he: {
                        word: "אבוקדו",
                        sound: "avocado"
                    },
                    en: {
                        word: "Avocado",
                        sound: "avocado"
                    }
                }
            },
        {
            image: "garlic.svg",
            lang: 
                {
                    he: {
                        word: "שום",
                        sound: "garlic"
                    },
                    en: {
                        word: "Garlic",
                        sound: "garlic"
                    }
                }
            },
        {
            image: "cheese.svg",
            lang: 
                {
                    he: {
                        word: "גבינה",
                        sound: "cheese"
                    },
                    en: {
                        word: "Cheese",
                        sound: "cheese"
                    }
                }
            },
        {
            image: "ribs.svg",
            lang: 
                {
                    he: {
                        word: "בשר",
                        sound: "ribs"
                    },
                    en: {
                        word: "Ribs",
                        sound: "ribs"
                    }
                }
            },
        {
            image: "pomegranate.svg",
            lang: 
                {
                    he: {
                        word: "רימון",
                        sound: "pomegranate"
                    },
                    en: {
                        word: "Pomegranate",
                        sound: "pomegranate"
                    }
                }
            },
        {
            image: "apple.svg",
            lang: 
                {
                    he: {
                        word: "תפוח",
                        sound: "apple"
                    },
                    en: {
                        word: "Apple",
                        sound: "apple"
                    }
                }
            },
        {
            image: "grapes.svg",
            lang: 
                {
                    he: {
                        word: "ענבים",
                        sound: "grapes"
                    },
                    en: {
                        word: "Grapes",
                        sound: "grapes"
                    }
                }
            },
        {
            image: "orange.svg",
            lang: 
                {
                    he: {
                        word: "תפוז",
                        sound: "orange"
                    },
                    en: {
                        word: "Orange",
                        sound: "orange"
                    }
                }
            },
        {
            image: "watermelon.svg",
            lang: 
                {
                    he: {
                        word: "אבטיח",
                        sound: "watermelon"
                    },
                    en: {
                        word: "Watermelon",
                        sound: "watermelon"
                    }
                }
            },
        {
            image: "cherry.svg",
            lang: 
                {
                    he: {
                        word: "דובדבן",
                        sound: "cherry"
                    },
                    en: {
                        word: "Cherry",
                        sound: "cherry"
                    }
                }
        }
        ]
    },
    {
        category: {
            title: {
                he: "יְרָקוֹת וּפֵרוֹת",
                en: "Fruits & Vegetables"
            },
            value: "fruits-and-vegetables",
            image: "/images/fruits-and-vegetables/027-strawberry.svg"
        },
        words: [
        {
            image: "008-pepper.svg",
            lang: 
                {
                    he: {
                        word: "גמבה",
                        sound: "008-pepper"
                    },
                    en: {
                        word: "Pepper",
                        sound: "pepper"
                    }
                }
            },
        {
            image: "010-beet.svg",
            lang: 
                {
                    he: {
                        word: "סלק",
                        sound: "010-beet"
                    },
                    en: {
                        word: "Beet",
                        sound: "beet"
                    }
                }
            },
        {
            image: "043-banana.svg",
            lang: 
                {
                    he: {
                        word: "בננה",
                        sound: "043-banana"
                    },
                    en: {
                        word: "Banana",
                        sound: "banana"
                    }
                }
            },
        {
            image: "023-salad.svg",
            lang: 
                {
                    he: {
                        word: "סלט",
                        sound: "023-salad"
                    },
                    en: {
                        word: "Salad",
                        sound: "salad"
                    }
                }
            },
        {
            image: "018-onion.svg",
            lang: 
                {
                    he: {
                        word: "בצל",
                        sound: "018-onion"
                    },
                    en: {
                        word: "Onion",
                        sound: "onion"
                    }
                }
            },
        {
            image: "029-lemon.svg",
            lang: 
                {
                    he: {
                        word: "לימון",
                        sound: "029-lemon"
                    },
                    en: {
                        word: "Lemon",
                        sound: "lemon"
                    }
                }
            },
        {
            image: "039-mango.svg",
            lang: 
                {
                    he: {
                        word: "מנגו",
                        sound: "039-mango"
                    },
                    en: {
                        word: "Mango",
                        sound: "mango"
                    }
                }
            },
        {
            image: "015-dill.svg",
            lang: 
                {
                    he: {
                        word: "שמיר",
                        sound: "015-dill"
                    },
                    en: {
                        word: "Dill",
                        sound: "dill"
                    }
                }
            },
        {
            image: "034-beans.svg",
            lang: 
                {
                    he: {
                        word: "שעועית",
                        sound: "034-beans"
                    },
                    en: {
                        word: "Beans",
                        sound: "beans"
                    }
                }
            },
        {
            image: "004-spinach.svg",
            lang: 
                {
                    he: {
                        word: "תרד",
                        sound: "004-spinach"
                    },
                    en: {
                        word: "Spinach",
                        sound: "spinach"
                    }
                }
            },
        {
            image: "019-garlic.svg",
            lang: 
                {
                    he: {
                        word: "שום",
                        sound: "019-garlic"
                    },
                    en: {
                        word: "Garlic",
                        sound: "garlic"
                    }
                }
            },
        {
            image: "044-cherry.svg",
            lang: 
                {
                    he: {
                        word: "דובדבן",
                        sound: "044-cherry"
                    },
                    en: {
                        word: "Cherry",
                        sound: "cherry"
                    }
                }
            },
        {
            image: "030-kiwi.svg",
            lang: 
                {
                    he: {
                        word: "קיוי",
                        sound: "030-kiwi"
                    },
                    en: {
                        word: "Kiwi",
                        sound: "kiwi"
                    }
                }
            },
        {
            image: "045-cabbage.svg",
            lang: 
                {
                    he: {
                        word: "כרוב",
                        sound: "045-cabbage"
                    },
                    en: {
                        word: "Cabbage",
                        sound: "cabbage"
                    }
                }
            },
        {
            image: "006-potato.svg",
            lang: 
                {
                    he: {
                        word: "תפוח   אדמה",
                        sound: "006-potato"
                    },
                    en: {
                        word: "Potato",
                        sound: "potato"
                    }
                }
            },
        {
            image: "046-pomegranate.svg",
            lang: 
                {
                    he: {
                        word: "רימון",
                        sound: "046-pomegranate"
                    },
                    en: {
                        word: "Pomegranate",
                        sound: "pomegranate"
                    }
                }
            },
        {
            image: "020-zucchini.svg",
            lang: 
                {
                    he: {
                        word: "קישוא",
                        sound: "020-zucchini"
                    },
                    en: {
                        word: "Zucchini",
                        sound: "zucchini"
                    }
                }
            },
        {
            image: "012-cucumber.svg",
            lang: 
                {
                    he: {
                        word: "מלפפון",
                        sound: "012-cucumber"
                    },
                    en: {
                        word: "Cucumber",
                        sound: "cucumber"
                    }
                }
            },
        {
            image: "017-hot-pepper.svg",
            lang: 
                {
                    he: {
                        word: "פלפל   חריף",
                        sound: "017-hot-pepper"
                    },
                    en: {
                        word: "Hot pepper",
                        sound: "hot-pepper"
                    }
                }
            },
        {
            image: "041-coconut.svg",
            lang: 
                {
                    he: {
                        word: "קוקוס",
                        sound: "041-coconut"
                    },
                    en: {
                        word: "Coconut",
                        sound: "coconut"
                    }
                }
            },
        {
            image: "011-radish.svg",
            lang: 
                {
                    he: {
                        word: "צנון",
                        sound: "011-radish"
                    },
                    en: {
                        word: "Radish",
                        sound: "radish"
                    }
                }
            },
        {
            image: "049-brussels-sprouts.svg",
            lang: 
                {
                    he: {
                        word: "נבטים",
                        sound: "049-brussels-sprouts"
                    },
                    en: {
                        word: "Brussels sprouts",
                        sound: "brussels-sprouts"
                    }
                }
            },
        {
            image: "002-cauliflower.svg",
            lang: 
                {
                    he: {
                        word: "כרובית",
                        sound: "002-cauliflower"
                    },
                    en: {
                        word: "Cauliflower",
                        sound: "cauliflower"
                    }
                }
            },
        {
            image: "005-mushroom.svg",
            lang: 
                {
                    he: {
                        word: "פטריות",
                        sound: "005-mushroom"
                    },
                    en: {
                        word: "Mushrooms",
                        sound: "mushrooms"
                    }
                }
            },
        {
            image: "001-tomato.svg",
            lang: 
                {
                    he: {
                        word: "עגבניה",
                        sound: "001-tomato"
                    },
                    en: {
                        word: "Tomato",
                        sound: "tomato"
                    }
                }
            },
        {
            image: "007-pumpkin.svg",
            lang: 
                {
                    he: {
                        word: "דלעת",
                        sound: "007-pumpkin"
                    },
                    en: {
                        word: "Pumpkin",
                        sound: "pumpkin"
                    }
                }
            },
        {
            image: "022-celery.svg",
            lang: 
                {
                    he: {
                        word: "סלרי",
                        sound: "022-celery"
                    },
                    en: {
                        word: "Celery",
                        sound: "celery"
                    }
                }
            },
        {
            image: "047-cabbage.svg",
            lang: 
                {
                    he: {
                        word: "כרוב",
                        sound: "047-cabbage"
                    },
                    en: {
                        word: "Cabbage",
                        sound: "cabbage"
                    }
                }
            },
        {
            image: "033-apple.svg",
            lang: 
                {
                    he: {
                        word: "תפוח",
                        sound: "033-apple"
                    },
                    en: {
                        word: "Apple",
                        sound: "apple"
                    }
                }
            },
        {
            image: "038-melon.svg",
            lang: 
                {
                    he: {
                        word: "מלון",
                        sound: "038-melon"
                    },
                    en: {
                        word: "Melon",
                        sound: "melon"
                    }
                }
            },
        {
            image: "024-onion.svg",
            lang: 
                {
                    he: {
                        word: "בצל",
                        sound: "024-onion"
                    },
                    en: {
                        word: "Onion",
                        sound: "onion"
                    }
                }
            },
        {
            image: "021-eggplant.svg",
            lang: 
                {
                    he: {
                        word: "חציל",
                        sound: "021-eggplant"
                    },
                    en: {
                        word: "Eggplant",
                        sound: "eggplant"
                    }
                }
            },
        {
            image: "048-peas.svg",
            lang: 
                {
                    he: {
                        word: "אפונה",
                        sound: "048-peas"
                    },
                    en: {
                        word: "Peas",
                        sound: "peas"
                    }
                }
            },
        {
            image: "025-raspberries.svg",
            lang: 
                {
                    he: {
                        word: "פטל",
                        sound: "025-raspberries"
                    },
                    en: {
                        word: "Raspberries",
                        sound: "raspberries"
                    }
                }
            },
        {
            image: "003-broccoli.svg",
            lang: 
                {
                    he: {
                        word: "ברוקולי",
                        sound: "003-broccoli"
                    },
                    en: {
                        word: "Broccoli",
                        sound: "broccoli"
                    }
                }
            },
        {
            image: "013-carrot.svg",
            lang: 
                {
                    he: {
                        word: "גזר",
                        sound: "013-carrot"
                    },
                    en: {
                        word: "Carrot",
                        sound: "carrot"
                    }
                }
            },
        {
            image: "035-pear.svg",
            lang: 
                {
                    he: {
                        word: "אגס",
                        sound: "035-pear"
                    },
                    en: {
                        word: "Pear",
                        sound: "pear"
                    }
                }
            },
        {
            image: "028-pineapple.svg",
            lang: 
                {
                    he: {
                        word: "אננס",
                        sound: "028-pineapple"
                    },
                    en: {
                        word: "Pineapple",
                        sound: "pineapple"
                    }
                }
            },
        {
            image: "032-avocado.svg",
            lang: 
                {
                    he: {
                        word: "אבוקדו",
                        sound: "032-avocado"
                    },
                    en: {
                        word: "Avocado",
                        sound: "avocado"
                    }
                }
            },
        {
            image: "009-radish.svg",
            lang: 
                {
                    he: {
                        word: "צנון",
                        sound: "009-radish"
                    },
                    en: {
                        word: "Radish",
                        sound: "radish"
                    }
                }
            },
        {
            image: "050-corn.svg",
            lang: 
                {
                    he: {
                        word: "תירס",
                        sound: "050-corn"
                    },
                    en: {
                        word: "Corn",
                        sound: "corn"
                    }
                }
            },
        {
            image: "016-asparagus.svg",
            lang: 
                {
                    he: {
                        word: "אספרגוס",
                        sound: "016-asparagus"
                    },
                    en: {
                        word: "Asparagus",
                        sound: "asparagus"
                    }
                }
            },
        {
            image: "037-watermelon.svg",
            lang: 
                {
                    he: {
                        word: "אבטיח",
                        sound: "037-watermelon"
                    },
                    en: {
                        word: "Watermelon",
                        sound: "watermelon"
                    }
                }
            },
        {
            image: "027-strawberry.svg",
            lang: 
                {
                    he: {
                        word: "תות",
                        sound: "027-strawberry"
                    },
                    en: {
                        word: "Strawberry",
                        sound: "strawberry"
                    }
                }
            },
        {
            image: "042-grapes.svg",
            lang: 
                {
                    he: {
                        word: "ענבים",
                        sound: "042-grapes" 
                    },
                    en: {
                        word: "Grapes",
                        sound: "grapes"
                    }
                }
            },
        {
            image: "031-orange.svg",
            lang: 
                {
                    he: {
                        word: "תפוז",
                        sound: "031-orange"
                    },
                    en: {
                        word: "Orange",
                        sound: "orange"
                    }
                }
            },
        {
            image: "036-peach.svg",
            lang: 
                {
                    he: {
                        word: "אפרסק",
                        sound: "036-peach"
                    },
                    en: {
                        word: "Peach",
                        sound: "peach"
                    }
                }
        }
        ]
    },
    {
        category: {
            title: {
                he: "מִטְבָּח",
                en: "Kitchen"
            },
            value: "kitchen",
            image: "/images/kitchen/oven-mitts.svg"
        },
        words: [
        {
            image: "sunflower-oil.svg",
            lang: 
                {
                    he: {
                        word: "שמן",
                        sound: "sunflower-oil"
                    },
                    en: {
                        word: "Oil",
                        sound: "oil"
                    }
                }
            },
        {
            image: "toaster.svg",
            lang: 
                {
                    he: {
                        word: "מצנם",
                        sound: "toaster"
                    },
                    en: {
                        word: "Toaster",
                        sound: "toaster"
                    }
                }
            },
        {
            image: "oven-mitts.svg",
            lang: 
                {
                    he: {
                        word: "כפפה",
                        sound: "oven-mitts"
                    },
                    en: {
                        word: "Oven mitts",
                        sound: "oven-mitts"
                    }
                }
            },
        {
            image: "frying-pan.svg",
            lang: 
                {
                    he: {
                        word: "מחבת",
                        sound: "frying-pan"
                    },
                    en: {
                        word: "Frying pan",
                        sound: "frying-pan"
                    }
                }
            },
        {
            image: "fork.svg",
            lang: 
                {
                    he: {
                        word: "סכום",
                        sound: "fork"
                    },
                    en: {
                        word: "Silverware",
                        sound: "silverware"
                    }
                }
            },
        {
            image: "oven.svg",
            lang: 
                {
                    he: {
                        word: "תנור",
                        sound: "oven"
                    },
                    en: {
                        word: "Oven",
                        sound: "oven"
                    }
                }
            },
        {
            image: "electric-kettle.svg",
            lang: 
                {
                    he: {
                        word: "קומקום",
                        sound: "electric-kettle"
                    },
                    en: {
                        word: "Kettle",
                        sound: "kettle"
                    }
                }
            },
        {
            image: "kitchen.svg",
            lang: 
                {
                    he: {
                        word: "מטבח",
                        sound: "kitchen"
                    },
                    en: {
                        word: "Kitchen",
                        sound: "kitchen"
                    }
                }
            },
        {
            image: "dishwasher.svg",
            lang: 
                {
                    he: {
                        word: "מדיח",
                        sound: "dishwasher"
                    },
                    en: {
                        word: "Dishwasher",
                        sound: "dishwasher"
                    }
                }
            },
        {
            image: "fridge.svg",
            lang: 
                {
                    he: {
                        word: "מקרר",
                        sound: "fridge"
                    },
                    en: {
                        word: "Fridge",
                        sound: "fridge"
                    }
                }
            },
        {
            image: "pot.svg",
            lang: 
                {
                    he: {
                        word: "סיר",
                        sound: "pot"
                    },
                    en: {
                        word: "Pot",
                        sound: "pot"
                    }
                }
            },
        {
            image: "microwave-oven.svg",
            lang: 
                {
                    he: {
                        word: "מיקרוגל",
                        sound: "microwave-oven"
                    },
                    en: {
                        word: "Microwave oven",
                        sound: "microwave-oven"
                    }
                }
            },
        {
            image: "mixer.svg",
            lang: 
                {
                    he: {
                        word: "מיקסר",
                        sound: "mixer"
                    },
                    en: {
                        word: "Mixer",
                        sound: "mixer"
                    }
                }
        }
        ]
    },
    {
        category: {
            title: {
                he: "מַכְשִׁירִים",
                en: "Devices"
            },
            value: "devices",
            image: "/images/devices/desk-lamp.svg"
        },
        words: [
        {
            image: "smartphone.svg",
            lang: 
                {
                    he: {
                        word: "טלפון",
                        sound: "smartphone"
                    },
                    en: {
                        word: "Smartphone",
                        sound: "smartphone"
                    }
                }
            },
        {
            image: "television.svg",
            lang: 
                {
                    he: {
                        word: "טלויזיה",
                        sound: "television"
                    },
                    en: {
                        word: "Television",
                        sound: "television"
                    }
                }
            },
        {
            image: "compact-camera.svg",
            lang: 
                {
                    he: {
                        word: "מצלמה",
                        sound: "compact-camera"
                    },
                    en: {
                        word: "Camera",
                        sound: "camera"
                    }
                }
            },
        {
            image: "vacuum-cleaner.svg",
            lang: 
                {
                    he: {
                        word: "שואב   אבק",
                        sound: "vacuum-cleaner"
                    },
                    en: {
                        word: "Vacuum cleaner",
                        sound: "vacuum-cleaner"
                    }
                }
            },
        {
            image: "headphones.svg",
            lang: 
                {
                    he: {
                        word: "אוזניות",
                        sound: "headphones"
                    },
                    en: {
                        word: "Headphones",
                        sound: "headphones"
                    }
                }
            },
        {
            image: "fan.svg",
            lang: 
                {
                    he: {
                        word: "מאורר",
                        sound: "fan"
                    },
                    en: {
                        word: "Fan",
                        sound: "fan"
                    }
                }
            },
        {
            image: "speaker.svg",
            lang: 
                {
                    he: {
                        word: "רמקול",
                        sound: "speaker"
                    },
                    en: {
                        word: "Speaker",
                        sound: "speaker"
                    }
                }
            },
        {
            image: "smartwatch.svg",
            lang: 
                {
                    he: {
                        word: "שעון",
                        sound: "smartwatch"
                    },
                    en: {
                        word: "Smartwatch",
                        sound: "smartwatch"
                    }
                }
            },
        {
            image: "iron.svg",
            lang: 
                {
                    he: {
                        word: "מגהץ",
                        sound: "iron"
                    },
                    en: {
                        word: "Iron",
                        sound: "iron"
                    }
                }
            },
        {
            image: "refrigerator.svg",
            lang: 
                {
                    he: {
                        word: "מקרר",
                        sound: "refrigerator"
                    },
                    en: {
                        word: "Refrigerator",
                        sound: "refrigerator"
                    }
                }
            },
        {
            image: "washing-machine.svg",
            lang: 
                {
                    he: {
                        word: "מכונת  כביסה",
                        sound: "washing-machine"
                    },
                    en: {
                        word: "Washing machine",
                        sound: "washing-machine"
                    }
                }
            },
        {
            image: "tablet.svg",
            lang: 
                {
                    he: {
                        word: "טאבלט",
                        sound: "tablet"
                    },
                    en: {
                        word: "Tablet",
                        sound: "tablet"
                    }
                }
            },
        {
            image: "desk-lamp.svg",
            lang: 
                {
                    he: {
                        word: "מנורה",
                        sound: "desk-lamp"
                    },
                    en: {
                        word: "Lamp",
                        sound: "lamp"
                    }
                }
        }
        ]
    }
]