import ReactGA from 'react-ga'

export const tagPageView = (url, title) => {
    /*window.dataLayer.push({
        event: 'pageview',
        page: {
            url: url,
            title: title
        }
    })*/

    ReactGA.pageview({ 
        path: url, 
        title: title
    })
}

export const tagEvent = (category, action, label) => {
    /*window.dataLayer.push({
        event: 'event', 
        eventProps: {
            category: category,
            action: action, 
            label: label
        }
    })*/

    ReactGA.event({ 
        category: category,
        action: action, 
        label: label
    })
}