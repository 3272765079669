/* eslint-disable max-len */
import React, {useState} from 'react'

import TextField from '@material-ui/core/TextField'

import './GameLetterTile.css'

function GameLetterTile (props) {
    const [errorText, setErrorText] = useState('')
    
    const className = props.value === '' ? 'letter emptyletter' : 'letter'

    function handleChange (e) {
        e.preventDefault();
        const lastLetter = e.target.value.charAt(e.target.value.length - 1)
        e.target.value = props.uppercase === true ? lastLetter.toUpperCase() : lastLetter
        if (e.target.value.toLowerCase().match(props.value.toLowerCase())) {
            // correct answer!
            setErrorText('')
            e.target.disabled = true
            props.handleSceneSolved(props.idx, true)
        } else {
            setErrorText('טעות')
            props.handleSceneSolved(props.idx, false)
        }
    }

    return (
        props.value === '' ? 
            <div style={{ width: '100%', display: 'table' }}>
                <div style={{display: 'none'}}>
                        <TextField 
                            id={'txt' + props.idx}
                            label='' 
                            defaultValue=''
                            size='small' 
                            disabled={props.disabled}
                            autoFocus={false}
                            variant='outlined' 
                            className={className}
                            inputProps={{ maxLength: 0 }}
                            inputRef = {props.referer}
                            error = {false}
                        />
                </div>
            </div> : 
            <TextField 
                id={'txt' + props.idx}
                label='' 
                defaultValue={props.disabled ? props.value : ''}
                size='small' 
                disabled={props.disabled} 
                autoFocus={props.autoFocus}
                variant='outlined' 
                className={className}
                inputProps={{ maxLength: 2 }}
                style={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    marginLeft: 1,
                    marginRight: 1
                }} 
                inputRef = {props.referer}
                error = {errorText.length !== 0} 
                onChange={handleChange.bind(this)} />
    )
}

export default GameLetterTile
