import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Route, Redirect, withRouter } from 'react-router-dom'

import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'
import { tagPageView } from './App/Common/TagManager'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import './i18n'

import { MENU_ITEMS } from './App/Common/Global'

import Dashboard from './App/Dashboard'
import Footer from './App/Common/Footer'
import Menu from './App/Common/Header/Menu'
import MenuItem from './App/Common/Header/MenuItem'
import MenuButton from './App/Common/Header/MenuButton'
import LanguageSelector from './App/Common/LanguageSelector'

import './App.css';

const tagManagerArgs = { gtmId: 'GTM-MLWXFXB' }
const trackingId = "UA-182871295-1"

function App(props) {
  
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [showLanguageSelector, setShowLanguageSelector] = useState('block');

  const menu = [i18next.t('newgame.label'), i18next.t('about.label')/*, i18next.t('statistics.label')*/]
  const menuItems = menu.map((val, index) => (
      <MenuItem
          id={index}
          key={index}
          delay={`${index * 0.1}s`}
          onClick={handleLinkClick}>{val}</MenuItem>))
          
  function handleMenuClick(e) {
    e.preventDefault();
    // console.log('handleMenuClick.');
    setIsOpen(!isOpen)
  }
  function handleLinkClick(e) {
    e.preventDefault();

    switch(e.target.id) {
      case MENU_ITEMS.NewGame: //new game
        history.push({
          pathname: '/'
        })
        break
      case MENU_ITEMS.About: //about
        history.push({
          pathname: '/app/about'
        })
        break
        case MENU_ITEMS.Stats: //stats
        history.push({
          pathname: '/app/stats/summary'
        })
        break
      default:
        break
    }
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    ReactGA.initialize(trackingId)

    tagPageView('/', 'homepage')
  },[])

  useEffect(() => {
    //console.log(props.location.pathname) /* e.g. /app/game */
    if (props.location.pathname === '/app/game') {
      setShowLanguageSelector('none')
    } else {
      setShowLanguageSelector('block')
    }
}, [props.location.pathname])

  return (
      <div className='app'>
          <div className='content'>
            <div className={'container ' + i18next.t('direction') }>
              <MenuButton open={isOpen} onClick={handleMenuClick} color='white'/>
              <div style={{display:showLanguageSelector}}><LanguageSelector /> </div>
              <div className='logo'>{ i18next.t('title.label') }</div>
            </div>
            <Menu open={isOpen}>
                {menuItems}
            </Menu>
            <Route exact path='/' render={() => (
                <Redirect to={{ pathname: '/app' }} />
            )} />
            <Dashboard />
          </div>
          <Footer />
      </div>
  );
}

export default withRouter(withTranslation()(App))
