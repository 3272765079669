import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import queryString from 'query-string'

import Grid from '@material-ui/core/Grid'
import Particles from 'react-particles-js'

import { LEVELS } from '../Common/Global'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { tagPageView, tagEvent } from '../Common/TagManager'

import GameTile from './GameTile'

import './GameLevel.css'

function GameLevel (props) {
    useEffect(() => {
        tagPageView(window.location.hash.replace('#', ''), 'choose level')
    },[])
    
    const { width, height } = useWindowSize()
    const history = useHistory()
    let { category } = queryString.parse(props.location.search)

    const handleGameTileClick = (value) => {
        tagEvent('game', 'level', value)
        
        history.push({
            pathname: '/app/game',
            search: '?category=' + category + '&level=' + value,
            state: { category: category, level: value }
        })
    }

    useEffect(() => {
        localStorage.removeItem('wordsSolvedArray')
        localStorage.removeItem('currentWordTextFieldsSolved')
    });

    return (
        <div className='home'>
            <div className={'game-body ' + i18next.t('direction') + ' ' }>
                <div className='particles-container'>
                <Particles
                    width={width}
                    height={height}
                    style={{ width: width, height:height }}
                    params={{
                        "particles": {
                            "number": {
                                "value": 160,
                                "density": {
                                    "enable": false
                                }
                            },
                            "size": {
                                "value": 3,
                                "random": true,
                                "anim": {
                                    "speed": 4,
                                    "size_min": 0.3
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "random": true,
                                "speed": 1,
                                "direction": "top",
                                "out_mode": "out"
                            }
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "bubble"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            },
                            "modes": {
                                "bubble": {
                                    "distance": 250,
                                    "duration": 2,
                                    "size": 0,
                                    "opacity": 0
                                },
                                "repulse": {
                                    "distance": 400,
                                    "duration": 4
                                }
                            }
                        }
                    }} />
                </div>
                <Grid style={{ marginTop:0, zIndex:200 }} container direction='row' justify='center' alignItems='center' spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <GameTile gameTile={{ value:'first-letter', title:i18next.t(LEVELS.FirstLetter), imageurl: process.env.PUBLIC_URL + '/first-letter.svg', soundUrl: process.env.PUBLIC_URL + '/sounds/' + i18next.language + '/first-letter' + (i18next.language === 'he' ? '.m4a' : '.mp3') }} onGameTileClick={handleGameTileClick}></GameTile>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <GameTile gameTile={{ value:'last-letter', title:i18next.t(LEVELS.LastLetter), imageurl: process.env.PUBLIC_URL + '/last-letter.svg', soundUrl: process.env.PUBLIC_URL + '/sounds/' + i18next.language + '/last-letter' + (i18next.language === 'he' ? '.m4a' : '.mp3') }} onGameTileClick={handleGameTileClick}></GameTile>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <GameTile gameTile={{ value:'middle-letter', title:i18next.t(LEVELS.MiddleLetter), imageurl: process.env.PUBLIC_URL + '/middle-letter.svg', soundUrl: process.env.PUBLIC_URL + '/sounds/' + i18next.language + '/middle-letter' + (i18next.language === 'he' ? '.m4a' : '.mp3') }} onGameTileClick={handleGameTileClick}></GameTile>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <GameTile gameTile={{ value:'entire-word', title:i18next.t(LEVELS.EntireWord), imageurl: process.env.PUBLIC_URL + '/entire-word.svg', soundUrl: process.env.PUBLIC_URL + '/sounds/' + i18next.language + '/entire-word' + (i18next.language === 'he' ? '.m4a' : '.mp3') }} onGameTileClick={handleGameTileClick}></GameTile>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default withTranslation()(GameLevel)
