/* eslint-disable max-len */
import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { SUB_TYPE } from '../Common/Global'

import './StatsTile.css'

function StatsTile (props) {

    /* <StatsTile tile={{ title:'Words Solved', value1:'212', value2:'500', type:'number/text', subtype:'good/bad' }} ></StatsTile> */
    return (
        <Card className='stats-tile-root' variant="outlined">
            <CardContent className='stats-tile-card-content' style={{padding:5, height:'18vh'}}>
                <Typography className='stats-tile-title' variant='button' color="textSecondary" gutterBottom>
                    { props.tile.title }:
                </Typography>
                <div className='stats-tile-values'>
                    <div className={'stats-tile-type-number-' + props.tile.type}>
                        <Typography variant="h3" className={'stats-tile-card-title1 stats-tile-card-title1-' + props.tile.subtype}>
                            { props.tile.value1 }
                        </Typography>
                        &nbsp;<Typography variant='body2' color="textSecondary">{ props.tile.value2 ? "/ " + props.tile.value2 : '' }</Typography>
                        <Typography variant='subtitle2' color="textSecondary">{ props.tile.value3 }</Typography>
                    </div>
                    <div className={'stats-tile-type-text-' + props.tile.type}>
                    <Typography variant="h5" className={'stats-tile-card-title1 stats-tile-card-title1-' + props.tile.subtype}>
                            { props.tile.value1 }
                        </Typography>
                        <Typography variant='body2' color="textSecondary">{ props.tile.value2 }</Typography>
                        <Typography variant='subtitle2' color="textSecondary">{ props.tile.value3 }</Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default StatsTile
