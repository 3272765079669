import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import { tagPageView, tagEvent } from '../Common/TagManager'

import Grid from '@material-ui/core/Grid'
import Particles from 'react-particles-js'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { gameData } from "./GameData"
import GameTile from './GameTile'

import './Home.css'

function Home (props) {
    const history = useHistory()
    const { width, height } = useWindowSize()
    const [categories, setCategories] = useState([])

    
    useEffect(() => {
        tagPageView('/app', 'choose category')
    },[])

    
    const handleGameTileClick = (value) => {

        tagEvent('game', 'category', value)

        history.push({
            pathname: '/app/level',
            search: '?category=' + value,
            state: { category: value }
        })
    }

    const initCategoriesArray = () => {
        //console.log('initCategoriesArray')
        if (categories.length <= 0) {
            const tempCategories = new Array(gameData.length)

            for (var i=0; i < gameData.length; i++) {
                const category = gameData[i].category
                tempCategories[i] = category
            }
            setCategories(tempCategories)
        }
    }

    useEffect(() => {
        localStorage.removeItem('wordsSolvedArray')
        localStorage.removeItem('currentWordTextFieldsSolved')

        initCategoriesArray()
    });
    
    
    return (
        <div className='home'>
            <div className={'game-body ' + i18next.t('direction') }>
                <div className='particles-container'>
                <Particles
                    width={width}
                    height={height}
                    style={{ width: width, height:height }}
                    params={{
                        "particles": {
                            "number": {
                                "value": 160,
                                "density": {
                                    "enable": false
                                }
                            },
                            "size": {
                                "value": 3,
                                "random": true,
                                "anim": {
                                    "speed": 4,
                                    "size_min": 0.3
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "random": true,
                                "speed": 1,
                                "direction": "top",
                                "out_mode": "out"
                            }
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "bubble"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            },
                            "modes": {
                                "bubble": {
                                    "distance": 250,
                                    "duration": 2,
                                    "size": 0,
                                    "opacity": 0
                                },
                                "repulse": {
                                    "distance": 400,
                                    "duration": 4
                                }
                            }
                        }
                    }} />
                </div>

                <Grid style={{marginTop:0, zIndex:200}} container direction='row' justify='center' alignItems='center' spacing={3}>
                {
                    categories.map((category, key) => (
                        <Grid item xs={6} sm={3}>
                            <GameTile gameTile={{ value:category.value, title:category.title[i18next.language], imageurl: process.env.PUBLIC_URL + category.image, soundUrl: process.env.PUBLIC_URL + '/sounds/' + i18next.language +  '/' + category.value + '/' + category.value + '_category' + (i18next.language === 'he' ? '.m4a' : '.mp3') }} onGameTileClick={handleGameTileClick}></GameTile>
                        </Grid>  
                    ))
                }
                </Grid>
            </div>
        </div>
    )
}

export default withTranslation()(Home)
