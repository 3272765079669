import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//import queryString from 'query-string'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CardMedia from '@material-ui/core/CardMedia'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Grid from '@material-ui/core/Grid'

import { LEVEL_NUM, SUB_TYPE } from '../Common/Global'
import { tagPageView, tagEvent } from '../Common/TagManager'
import StatsTile from './StatsTile'
import { gameData } from '../Game/GameData'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import './StatsSummary.css'
import './StatsTile.css'

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#fff',
      color: '-internal-light-dark(black, white)',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      padding: 2
    },
    body: {
      fontSize: '0.8rem',
      color: '-internal-light-dark(black, white)',
      fontWeight: 'Bold'
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
  }))(TableRow);

const StatsSummary = (props) => {
    
    useEffect(() => {
        tagPageView('/app/stats/summary', 'stats summary')
    },[])
    const history = useHistory()

    //let { lang } = queryString.parse(props.location.search)
    //if (lang === null) { lang = 'he' }

    const [state, setState] = React.useState({
        lang: i18next.language,
        categories: [],
        totalWords: 0,
        rows: []
    })

    const handleRowClick = (value) => {
        //const category = props.location.state.category
        tagEvent('stats', 'category', value)
        console.log('row clicked: ' + value)
        
        history.push({
            pathname: '/app/stats/category',
            search: '?category=' + value,
            state: { category: value }
        })
    }

    function createData(category, icon, name, lastPlayed, avgTime, ratio) {
        return { category, icon, name, lastPlayed, avgTime, ratio }
    }

    useEffect(() => {
        createSummaryData() 
    }, [i18next.language]);

    
    const createSummaryData = () => {
        //if (state.categories.length > 0) { return }

        let gameCategories = []
        let gameWords = []
        let totalWords = 0

        for (var i=0; i < gameData.length; i++) {
            gameCategories.push(gameData[i].category)
            gameWords.push(gameData[i].words)
            totalWords += gameData[i].words.length
        }

        const rows =
            gameCategories.map((category) => (
                createData(category.value, process.env.PUBLIC_URL + category.image, category.title[i18next.language], '8/2/2020 6:41:32pm', 12.5, 100)
            ))

        setState({
            categories: gameCategories,
            totalWords: totalWords * LEVEL_NUM,
            rows: rows
        })
    }

    

    return (
        <div className='home'>
            <div className={'stats-body ' + i18next.t('direction') }>
                <Grid style={{ marginTop:0, marginBottom:10, zIndex:200 }} container direction='row' justify='center' alignItems='center' spacing={3}>
                    <Grid item xs={6} sm={3} style={{ padding:6 }}>
                        <StatsTile tile={{ title:i18next.t('wordssolved.label'), value1:212, value2:'', value3:'', type:'number', subtype:SUB_TYPE.Positive }} ></StatsTile>
                    </Grid>
                    <Grid item xs={6} sm={3} style={{ padding:6 }}>
                        <StatsTile tile={{ title:i18next.t('mistakes.label'), value1:4889, value2:'', value3:'', type:'number', subtype:SUB_TYPE.Negative }} ></StatsTile>
                    </Grid>
                    <Grid item xs={6} sm={3} style={{ padding:6 }}>
                        <StatsTile tile={{ title:i18next.t('mostplayed.label'), value1:'Fruits & Vegetables', value2:i18next.t('first-letter'), value3:'17 ' + i18next.t('times.label'), type:'text', subtype:SUB_TYPE.Positive }} ></StatsTile>
                    </Grid>
                    <Grid item xs={6} sm={3} style={{ padding:6 }}>
                        <StatsTile tile={{ title:i18next.t('slowestsolved.label'), value1:'Brussels Sprouts', value2:'Fruits & Vegetables', value3:'128 ' + i18next.t('seconds.label'), type:'text', subtype:SUB_TYPE.Negative }}></StatsTile>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} elevation={0}>
                    <Table className='table-body'>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align={i18next.t('align')}></StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{ i18next.t('category.label') }</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{ i18next.t('lastplayed.label') }</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{ i18next.t('averagetime.label') }</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{ i18next.t('ratio.label') } - { i18next.t('wordssolved.label') } / { i18next.t('totalwords.label') }</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}></StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {(state.rows || []).map((row) => (
                            <StyledTableRow key={row.category} onClick={() => handleRowClick(row.category)}>
                            <StyledTableCell align='center'>
                                <CardMedia style={{backgroundSize:'contain'}} className='table-row-media' image={row.icon} />
                            </StyledTableCell>
                            <StyledTableCell component='th' scope='row' align={i18next.t('align')}>
                                <div className='table-cell-name'>
                                    {row.name}
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{row.lastPlayed}</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>{row.avgTime + ' ' + i18next.t('seconds.label')}</StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>
                                <div className='table-cell-ratio'>
                                    <Typography>{row.ratio}%</Typography>
                                    <LinearProgress color='secondary' variant='determinate' value={row.ratio}/>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align={i18next.t('align')}>
                                {i18next.language === 'he' ? <ArrowBackIos /> : <ArrowForwardIosIcon /> }
                            </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default withTranslation()(StatsSummary)
