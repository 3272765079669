import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//import useWindowSize from 'react-use/lib/useWindowSize'
import queryString from 'query-string'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded'
import ArrowForward from '@material-ui/icons/ArrowForward'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import '../../i18n'

import { tagPageView, tagEvent } from '../Common/TagManager'
import StatsCategoryTile from './StatsCategoryTile'
import { gameData } from '../Game/GameData'
import { LEVELS, WORD_STATUS } from '../Common/Global'

import './StatsCategory.css'

function StatsCategory (props) {
    
    useEffect(() => {
        tagPageView('/app/stats/category', 'stats category')
    },[])
    //const { width, height } = useWindowSize()
    const history = useHistory()
    let { category } = queryString.parse(props.location.search)

    const [state, setState] = React.useState({
        category: '',
        status: WORD_STATUS.All,
        level: LEVELS.All,
        words: [],
        rows: []
    })

    const handleBackClick = (value) => {
        //const category = props.location.state.category
        tagEvent('stats', 'category back')
        
        history.push({
            pathname: '/app/stats/summary'
        })
    }

    const handleChange = (event) => {
        const name = event.target.name
        // console.log('name: '+ name)
        // console.log('val: '+ event.target.value)
        setState({
            ...state,
            [name]: event.target.value,
        })
      };

      useEffect(() => {
          //console.log('createCategoryData')
        createCategoryData(i18next.language)
    },[i18next.language]);


    function createWordData(key, word, level, lastPlayed, playTime, image, status) {
        return { key, word, level, lastPlayed, playTime, image, status }
    }

    const createCategoryData = (lang) => {
        //if (state.words.length > 0) { return }

        let gameWords = []
        let cat = category

        for (var i=0; i < gameData.length; i++) {
            if (gameData[i].category.value === category ) {
                gameWords = gameData[i].words
                cat = gameData[i].category.title[lang]
                break;
            }
        }

        const rows =
            gameWords.map((word) => (
                createWordData(word.image.replace('.svg',''), word.lang[lang].word, state.level, '8/2/2020 6:41:32pm', 12.5, "/images/" + category + "/" + word.image, state.status)
            ))
        
       console.log (rows)
        

        setState({
            words: gameWords,
            rows: rows,
            category: cat
        })
    }

    return (
        <div className='home'>
            <div className={'stats-body ' + i18next.t('direction') }>
                <div className='stats-cat-header'>
                    <IconButton color="primary" aria-label="back" onClick={() => handleBackClick()}>
                        { i18next.language === 'he' ? <ArrowForward /> : <ArrowBackRounded /> }
                    </IconButton>
                    <Typography className='stats-cat-title' variant='h6' component='h1' color="textSecondary" gutterBottom>
                        { state.category }
                    </Typography>
                </div>
                <div className='stats-cat-filters'>
                    <FormControl className='stats-cat-form-control'>
                        <InputLabel htmlFor="status-native-simple">{i18next.t('status.label')}</InputLabel>
                        <Select
                            native
                            value={state.status}
                            onChange={handleChange}
                            label="Status"
                            inputProps={{
                                name: 'status',
                                id: 'status-native-simple',
                            }}
                            >
                            <option value={WORD_STATUS.All}>{i18next.t(WORD_STATUS.All)}</option>
                            <option value={WORD_STATUS.Solved}>{i18next.t(WORD_STATUS.Solved)}</option>
                            <option value={WORD_STATUS.Mistake}>{i18next.t(WORD_STATUS.Mistake)}</option>
                            <option value={WORD_STATUS.Pending}>{i18next.t(WORD_STATUS.Pending)}</option>
                        </Select>
                    </FormControl>
                    <FormControl className='stats-cat-form-control'>
                        <InputLabel htmlFor="level-native-simple">{i18next.t('level.label')}</InputLabel>
                        <Select
                            native
                            value={state.level}
                            onChange={handleChange}
                            label='Level'
                            inputProps={{
                                name: 'level',
                                id: 'level-native-simple',
                            }}
                            >
                            <option value={LEVELS.All}>{i18next.t(LEVELS.All)}</option>
                            <option value={LEVELS.FirstLetter}>{i18next.t(LEVELS.FirstLetter)}</option>
                            <option value={LEVELS.LastLetter}>{i18next.t(LEVELS.LastLetter)}</option>
                            <option value={LEVELS.MiddleLetter}>{i18next.t(LEVELS.MiddleLetter)}</option>
                            <option value={LEVELS.EntireWord}>{i18next.t(LEVELS.EntireWord)}</option>
                        </Select>
                    </FormControl>
                </div>
                <div className='stats-cat-body'>
                    <Grid style={{ marginTop:0, marginBottom:10, zIndex:200, justifyContent:'center' }} container direction='row' spacing={3}>
                        {(state.rows || []).map((row) => (
                            <StatsCategoryTile key={row.key} word={row.word} level={row.level} lastPlayed={row.lastPlayed} playTime={row.playTime} image={row.image} status={row.status} />
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(StatsCategory)
